import { Offer } from "@kalecard/common";

export enum OffersActionType {
  SET_OFFERS,
  UPDATE_OFFERS,
  NEW_OFFER,
  UPDATE_OFFER,
}

export interface OffersState {
  offers: Offer[];
  hasNextPage?: boolean;
  after?: string;
}

export interface OffersAction {
  type: OffersActionType;
  payload: OffersState | Offer;
}

function updateOfferIfApplicable(tempOffer: Offer, offer: Offer): Offer {
  return tempOffer.id === offer.id ? offer : tempOffer;
}

export function OffersReducer(
  state: OffersState,
  action: OffersAction
): OffersState {
  switch (action.type) {
    case OffersActionType.SET_OFFERS: {
      const { offers, hasNextPage, after } = action.payload as OffersState;
      return {
        ...state,
        offers: offers,
        hasNextPage: hasNextPage,
        after: after,
      };
    }

    case OffersActionType.UPDATE_OFFERS: {
      const { offers, hasNextPage, after } = action.payload as OffersState;

      const updatedOffers = [...state.offers, ...offers];
      return {
        ...state,
        offers: updatedOffers,
        hasNextPage: hasNextPage,
        after: after,
      };
    }

    case OffersActionType.NEW_OFFER: {
      const offer = action.payload as Offer;
      return {
        ...state,
        offers: [offer, ...state.offers],
      };
    }

    case OffersActionType.UPDATE_OFFER: {
      const offer = action.payload as Offer;

      const updatedOffers = state.offers
        .map((tempOffer) => {
          return updateOfferIfApplicable(tempOffer, offer);
        })
        .filter((tempOffer) => tempOffer);

      return {
        ...state,
        offers: updatedOffers,
      };
    }
  }
}
