import { useContext, useState } from "react";
import { OffersContext } from "../../providers/OffersProviders";
import { useLazyQuery } from "@apollo/client";
import { ADMIN_OFFERS, AdminOffersInterface } from "../../graphql/queries";
import { OffersActionType } from "../../reducers/OffersReducer";
import { Spinner } from "flowbite-react";
import OffersRow from "./OffersRow";

export default function OffersTable() {
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    state: { offers, hasNextPage, after },
    dispatch: offersDispatch,
  } = useContext(OffersContext);

  const [getAdminOffers] = useLazyQuery<AdminOffersInterface>(ADMIN_OFFERS);

  const getOffers = async () => {
    setIsLoading(true);
    const result = await getAdminOffers({
      variables: {
        after: after,
      },
    });
    setIsLoading(false);
    return result.data;
  };

  const getMoreOffers = async () => {
    setLoadingMore(true);
    await getOffers().then((data) => {
      offersDispatch({
        type: OffersActionType.UPDATE_OFFERS,
        payload: {
          challenges: data.adminOffers.edges.map((edge) => edge.node),
          hasNextPage: data.adminOffers.pageInfo.hasNextPage,
          after:
            data.adminOffers.edges[data.adminOffers.edges.length - 1]?.cursor,
        },
      });
    });
    setLoadingMore(false);
  };

  return (
    <div className="-mx-4 -my-2 overflow-x-auto pt-4 sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-fit py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-fit divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-4 py-4 text-center text-sm font-semibold text-gray-900"
                >
                  Brand
                </th>
                <th
                  scope="col"
                  className="px-4 py-4 text-center text-sm font-semibold text-gray-900"
                >
                  Cashback Percentage
                </th>
                <th
                  scope="col"
                  className="px-4 py-4 text-center text-sm font-semibold text-gray-900"
                >
                  State
                </th>
              </tr>
            </thead>
            {(!isLoading || loadingMore) && (
              <tbody className="divide-y divide-gray-200 bg-white">
                {offers?.map((offer) => {
                  return <OffersRow key={offer.id} offer={offer} />;
                })}
              </tbody>
            )}
          </table>
        </div>
        {(isLoading || loadingMore) && (
          <div className="mt-2 flex w-full justify-center">
            <Spinner size="h-5 w-5" />
          </div>
        )}
        {hasNextPage && !isLoading && (
          <div className="mt-2 flex w-full justify-center">
            <button
              type="button"
              onClick={getMoreOffers}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Load more
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
