import { createContext, useReducer } from "react";
import { OffersReducer, OffersState } from "../reducers/OffersReducer";

export const OffersContext = createContext<{
  state: OffersState;
  dispatch: React.Dispatch<{}>;
}>(null);

export default function OffersProvider({
  offersState,
  children,
}: {
  offersState?: OffersState;
  children?: any;
}) {
  const [state, dispatch] = useReducer(
    OffersReducer,
    getInitialState(offersState)
  );

  return (
    <OffersContext.Provider value={{ state: state, dispatch }}>
      {children}
    </OffersContext.Provider>
  );
}

function getInitialState(offersState: OffersState): OffersState {
  return {
    offers: offersState.offers || [],
    hasNextPage: offersState.hasNextPage,
    after: offersState.after,
  };
}
