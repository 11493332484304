import { MustardVerificationTool } from "./tools/MustardVerificationTool";
import React, { ReactNode } from "react";
import { BonsaiQueue, BonsaiQueueType } from "./BonsaiModels";
import { PostScoringTool } from "./tools/PostScoringTool";
import UserVerificationTool from "./tools/UserVerificationTool";
import { BrandProductCheckTool } from "./tools/BrandProductCheckTool";
import CommercialSoundCheckTool from "./tools/CommercialSoundCheckTool";
import MapBrandRejectionTool from "./tools/MapBrandRejectionTool";
import { PostScoringV2Tool } from "./tools/PostScoringV2Tool";
import { PostEligibilityTool } from "./tools/PostEligibilityTool";
import { ValidateContentModerationTool } from "./tools/ValidateContentModerationTool";
import { ReceiptVerificationTool } from "./tools/ReceiptVerificationTool";
import { ReviewGeminiCompletionTool } from "./tools/ReviewGeminiCompletionTool";
import { BrandRejectionReviewTool } from "./tools/BrandRejectionReviewTool";
import { ManualDownloadTool } from "./tools/ManualDownloadTool";
import ExperimentalPostScoringTool from "./tools/ExperimentalPostScoring";

export function bonsaiRegistry(
  queue: BonsaiQueue,
  onComplete: (outcome: any) => void,
  input: any,
  taskId: number,
  readOnly: boolean = false
): ReactNode {
  // ideally, we wouldn't have to parse this client side. But the server side graphql library
  // doesn't support custom scalars in a sane way.
  const parsedInput = JSON.parse(input);

  const commonProps = {
    onComplete,
    input: parsedInput,
    queue,
    readOnly,
    taskId,
  };

  switch (queue.type) {
    case BonsaiQueueType.MustardVerification:
      return <MustardVerificationTool {...commonProps} />;
    case BonsaiQueueType.PostScoring:
    case BonsaiQueueType.RaffiPostScoring:
    case BonsaiQueueType.VideoUploadPostScoring:
      return <PostScoringTool {...commonProps} />;
    case BonsaiQueueType.UserVerification:
      return <UserVerificationTool {...commonProps} />;
    case BonsaiQueueType.BrandProductCheck:
      return <BrandProductCheckTool {...commonProps} />;
    case BonsaiQueueType.CommercialSoundCheck:
      return <CommercialSoundCheckTool {...commonProps} />;
    case BonsaiQueueType.MapBrandRejection:
      return <MapBrandRejectionTool {...commonProps} />;
    case BonsaiQueueType.LivPostScoring:
    case BonsaiQueueType.IshaPostScoring:
      return <PostScoringV2Tool {...commonProps} />;
    case BonsaiQueueType.IshaPostEligibility:
    case BonsaiQueueType.LivPostEligibility:
      return <PostEligibilityTool {...commonProps} />;
    case BonsaiQueueType.ValidateContentModeration:
    case BonsaiQueueType.RequiredReviewContentModeration:
      return <ValidateContentModerationTool {...commonProps} />;
    case BonsaiQueueType.ReceiptVerification:
      return <ReceiptVerificationTool {...commonProps} />;
    case BonsaiQueueType.ReviewGeminiCompletion:
      return <ReviewGeminiCompletionTool {...commonProps} />;
    case BonsaiQueueType.BrandRejectionReview:
      return <BrandRejectionReviewTool {...commonProps} />;
    case BonsaiQueueType.ManualDownload:
      return <ManualDownloadTool {...commonProps} />;
    case BonsaiQueueType.ExperimentalPostScoring:
      return <ExperimentalPostScoringTool {...commonProps} />;
    default:
      return <div>No tool registered for queue!</div>;
  }
}
