import { useMutation, useQuery } from "@apollo/client";
import { Brand, Spinner } from "@kalecard/common";
import { BRAND, BrandData } from "../../graphql/queries";
import BrandPreview from "../brands/BrandPreview";
import { Button } from "../catalyst/button";
import { TURN_BRAND_ON_AS_KALE_BRAND } from "../../graphql/mutations";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";

export default function BrandGoLiveForm({ brandId } : { brandId: string }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };

  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const { loading: brandLoading, data } = useQuery<BrandData>(BRAND, {
    variables: {
      id: brandId,
    }
  });

  const [turnBrandOnAsKaleBrand] = useMutation(TURN_BRAND_ON_AS_KALE_BRAND);
  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const variables = {
        brandId: brandId
      };

      await turnBrandOnAsKaleBrand({
        variables: variables,
      });
      setErrorMessage(null);
      setSuccessMessage(`${data.brand.name} has been turned on as a Kale brand successfully!`);
      clearSuccessMessage();
    } catch (err) {
      console.error(err);
      setErrorMessage("Failed to turn brand on as kale brand, try again later.");
      clearErrorMessage();
    }
    setLoading(false);
  };

  return (
    <form>
      {!brandLoading ? (
        <div className="space-y-4">
          <BrandPreview brand={data.brand} />
          <div className="flex flex-row items-center justify-end space-x-4">
            {successMessage && (
              <p className="text-sm font-medium text-green-500">
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p className="text-sm font-medium text-red-500">{errorMessage}</p>
            )}
            {loading && (
              <div className="flex flex-wrap content-center justify-center pr-2">
                <Spinner size="h-6 w-6" />
              </div>
            )}
            <Button disabled={!data.brand.contentBudget || data.brand.contentBudget <= 0 || !data.brand.activeChallenges || data.brand.activeChallenges.length == 0} color="indigo" onClick={handleSubmit(onSubmit)} className="text-nowrap">
              Looks good, go live
            </Button>
          </div>        
        </div>
      ) : (
        <div className="flex flex-wrap content-center">
          <Spinner size="h-6 w-6" />
        </div>
      )}
    </form>
  )
}