import { Challenge, ChallengeHistoryData, ChallengeLog, Edge, Spinner } from "@kalecard/common";
import { useState } from "react";
import { CHALLENGE_HISTORY } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { ChallengeHistoryComponent } from "./ChallengeHistoryComponent";

export default function ChallengeHistory({ challenge }: { challenge: Challenge }) {
  const [edges, setEdges] = useState<Edge<ChallengeLog>[]>([]);
  const [after, setAfter] = useState(null);

  const onChallengeHistoryQueryComplete = (data: ChallengeHistoryData) => {
    console.log(data);
    setEdges([...edges, ...data.challengeHistory.edges]);
    setAfter(
      data.challengeHistory.edges[data.challengeHistory.edges.length - 1]?.cursor
    );
  }

  const { data, loading } = useQuery<ChallengeHistoryData>(
    CHALLENGE_HISTORY,
    {
      variables: {
        first: 20,
        after: after,
        challengeId: challenge.id
      },
      fetchPolicy: "network-only",
      skip: after != null,
      onCompleted: (data) => { onChallengeHistoryQueryComplete(data); }
    }
  );

  const canShowHistory = (edges.length > 1) || (edges.length === 1 && edges[0].node.createdAt === challenge.createdAt);
  
  return (
    <div className="max-h-[calc(100vh-120px)] space-y-4 px-2 overflow-y-auto overflow-x-hidden pb-8">
      <p className="font-medium text-lg">Challenge History</p>
      {canShowHistory && !loading && (
        <div>
          <div className="flow-root">
            <ul role="list" className="-mb-8 space-y-6">
              {edges.map((edge, index) => {
                const isLast = index === edges.length - 1;
                const isLastAndNotCreatedEntry = isLast && edge.node.createdAt !== challenge.createdAt;
                const isNextLastAndNotCreatedEntry = (index === edges.length - 2) && (edges[index + 1].node.createdAt !== challenge.createdAt);
                if (isLastAndNotCreatedEntry) {
                  return null;
                }
                return (
                  <div key={edge.node.id}>
                    <ChallengeHistoryComponent createdAt={challenge.createdAt} challengeLog={edge.node} nextChallengeLog={isLast ? null : edges[index + 1]?.node} brandLogoUrl={challenge?.brand?.logoUrl} willShowNextEntry={!isLast && !isNextLastAndNotCreatedEntry}/>
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      {loading && (
        <div className="flex justify-center mr-2">
          <Spinner size="h-6 w-6" />
        </div>
      )}
      {!canShowHistory && !loading && (
        <div className="text-sm">
          No history yet!
        </div>
      )}
    </div>
  );
}