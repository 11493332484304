import { useQuery } from "@apollo/client";
import { Loading } from "@kalecard/common";
import { NavLink, Route, Switch, useParams } from "react-router-dom";
import BrandLandingPageForm from "../components/brands/BrandLandingPageForm";
import { BRAND, BrandData } from "../graphql/queries";
import BrandActionsPage from "./BrandActionsPage";
import BrandTransactions from "../components/brands/BrandTransactions";
import BrandRequirementsPage from "./BrandRequirementsPage";
import BrandPaymentStructuresPage from "./BrandPaymentStructuresPage";
import { SagePage } from "./SagePage";
import UpdateBrandForm from "../components/forms/UpdateBrandForm";
import { Badge } from "../components/catalyst/badge";
import { Button } from "../components/catalyst/button";
import { Dialog } from "../components/catalyst/dialog";
import { useState } from "react";
import BrandGoLiveForm from "../components/forms/BrandGoLiveForm";
import BrandOffboardingForm from "../components/forms/BrandOffboardingForm";

export default function BrandPage() {
  const params = useParams();
  const brandId = params["id"];
  const tabs = [
    { name: "Setup", href: `/brands/${brandId}/setup`, current: true },
    {
      name: "Requirements",
      href: `/brands/${brandId}/requirements`,
      current: false,
    },
    {
      name: "Landing Page",
      href: `/brands/${brandId}/landing-page`,
      current: false,
    },
    { name: "Actions", href: `/brands/${brandId}/actions`, current: false },
    {
      name: "Transactions",
      href: `/brands/${brandId}/transactions`,
      current: false,
    },
    { name: "Payouts", href: `/brands/${brandId}/payouts`, current: false },
    { name: "Sage", href: `/brands/${brandId}/sage`, current: false },
  ];

  const [isBrandPreviewDialogOpen, setIsBrandPreviewDialogOpen] =
    useState(false);
  const [isBrandOffboardingDialogOpen, setIsBrandOffboardingDialogOpen] = useState(false);

  const { loading, data } = useQuery<BrandData>(BRAND, {
    variables: {
      id: brandId,
    },
    fetchPolicy: "network-only"
  });

  const getBrandLiveStatusBadge = (brand) => {
    switch (brand.status) {
      case "LIVE":
        return <Badge color="emerald">Live</Badge>;
      case "MISSING_INFO":
        return <Badge color="amber">Missing info</Badge>;
      case "READY_TO_GO_LIVE":
        return <Badge color="indigo">Ready to go live</Badge>;
      case "OFF_BOARDING":
        return <Badge color="cyan">Offboarding</Badge>;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="my-4 space-y-6 px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div className="flex items-center space-x-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {data.brand.name}
            </h3>
            {data.brand &&
              getBrandLiveStatusBadge(data.brand)}
          </div>
          <p className="mt-1 text-sm text-gray-500">
            Changes on this page will reflect immediately so be careful what you
            change.
          </p>
        </div>
        <div className="text-md flex items-center space-x-4 text-gray-900">
          {data.brand?.status === "READY_TO_GO_LIVE" && (
            <Button
              color="emerald"
              className="h-fit w-fit"
              onClick={() => setIsBrandPreviewDialogOpen(true)}
            >
              Go Live
            </Button>
          )}
          {data.brand?.status === "LIVE" && (
            <Button
              color="emerald"
              className="h-fit w-fit"
              onClick={() => setIsBrandOffboardingDialogOpen(true)}
            >
              Offboard
            </Button>
          )}
          <div className="flex space-x-1">
            <p className="font-medium">Brand ID:</p>
            <p>{brandId}</p>
          </div>
        </div>
      </div>
      <div className="border-b border-gray-200">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                activeClassName={
                  "text-indigo-600 whitespace-nowrap pb-2 px-1 border-b-2 border-indigo-300 font-medium text-sm"
                }
                className={
                  "whitespace-nowrap border-b-2 border-transparent px-1 pb-2 text-sm font-medium text-gray-500 hover:border-indigo-300 hover:text-indigo-700"
                }
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <div>
        <Switch>
          <Route path="/brands/:id/setup/:step">
            <UpdateBrandForm brandId={data.brand.id} />
          </Route>
          <Route path="/brands/:id/setup">
            <UpdateBrandForm brandId={data.brand.id} />
          </Route>
          <Route path="/brands/:id/requirements">
            <BrandRequirementsPage brand={data.brand} />
          </Route>
          <Route path="/brands/:id/landing-page">
            <BrandLandingPageForm brand={data.brand} />
          </Route>
          <Route path="/brands/:id/actions">
            <BrandActionsPage brand={data.brand} />
          </Route>
          <Route path="/brands/:id/transactions">
            <BrandTransactions brand={data.brand} />
          </Route>
          <Route path="/brands/:id/payouts">
            <BrandPaymentStructuresPage brand={data.brand} />
          </Route>
          <Route path="/brands/:id/sage">
            <SagePage brand={data.brand} />
          </Route>
        </Switch>
      </div>
      <Dialog
        open={isBrandPreviewDialogOpen}
        onClose={setIsBrandPreviewDialogOpen}
      >
        <BrandGoLiveForm brandId={brandId} />
      </Dialog>
      <Dialog
        open={isBrandOffboardingDialogOpen}
        onClose={setIsBrandOffboardingDialogOpen}
      >
        <BrandOffboardingForm brandId={brandId} />
      </Dialog>
    </div>
  );
}
