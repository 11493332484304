import { useMutation } from "@apollo/client";
import {
  Brand,
  BrandProduct,
  Challenge,
  OptionInterface,
  PostRequirement,
  Spinner,
} from "@kalecard/common";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CREATE_CHALLENGE } from "../../graphql/mutations";
import { BRANDS } from "../../graphql/queries";
import { getAllBrandCategories, getAllBrands } from "../../utils/brands";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../catalyst/button";
import {
  ChallengeFormDataComponent,
  getRoundedDate,
} from "../challenges/SharedChallengeComponents";

type NewChallengeFormInputs = {
  description: string;
  audienceType: string;
  newTag: string;
  newCreatorId: string;
  exampleUrl: string;
  title: string;
};

interface NewChallengeFormInterface {
  setNewChallenge?: (newChallenge: Challenge) => void;
  onSubmit?: (newChallenge: Challenge) => void;
}

export default function NewChallengeForm({
  setNewChallenge,
  onSubmit,
}: NewChallengeFormInterface) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { reset, register, handleSubmit, watch, getValues, setValue } =
    useForm<NewChallengeFormInputs>();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [brandCategories, setBrandCategories] = useState<OptionInterface[]>([]);
  const [brandOptions, setBrandOptions] = useState<OptionInterface[]>();
  const [tags, setTags] = useState<Set<string>>(new Set());
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [showAudience, setShowAudience] = useState<boolean>(false);
  const [brandAudience, setBrandAudience] = useState<OptionInterface[]>([]);
  const [brandCategoriesAudience, setBrandCategoriesAudience] = useState<
    OptionInterface[]
  >([]);
  const [creatorsAudience, setCreatorsAudience] = useState<Set<string>>(
    new Set()
  );
  const [isCommercialSoundsOnly, setIsCommercialSoundsOnly] = useState(false);
  const [difficulty, setDifficulty] = useState(1);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [challengeCategory, setChallengeCategory] = useState(null);
  const [createChallenge] = useMutation(CREATE_CHALLENGE);
  const [selectedBrandProduct, setSelectedBrandProduct] = useState(null);
  const [isChallengeReviewRequired, setIsChallengeReviewRequired] =
    useState(false);
  const [postRequirements, setPostRequirements] = useState([]);
  const [challengePostRequirements, setChallengePostRequirements] = useState(
    []
  );

  const getChallenge = (
    description: string,
    audienceType: string,
    brandId: string,
    isCommercialSoundsOnly: boolean,
    difficulty: number,
    requiredBrandProducts: BrandProduct[],
    postRequirements: PostRequirement[],
    challengePostRequirements: PostRequirement[],
    newTags?: Set<string>,
    exampleUrl?: string,
    title?: string
  ): Challenge => {
    const tagRequirements = newTags
      ? Array.from(newTags).join(" ")
      : Array.from(tags).join(" ");
    const brand = brands.find((brand) => brand.id === brandId);
    var mentionRequirements = null;
    if (brand) {
      mentionRequirements = "Tag " + brand.name;
      if (brand.socialHandle === brand.tiktokSocialHandle) {
        mentionRequirements = "Tag " + brand.socialHandle;
      }
    }

    return {
      id: "-1",
      description: description,
      brand: brand,
      tagRequirements,
      audienceType,
      mentionRequirements,
      state: "INACTIVE",
      exampleUrl: exampleUrl,
      difficulty: difficulty,
      isCommercialSoundsOnly: isCommercialSoundsOnly,
      requiredBrandProducts: requiredBrandProducts,
      postRequirements: [...challengePostRequirements, ...postRequirements],
      title: title,
    };
  };

  const updateChallenge = (
    brandId: string,
    isCommercialSoundsOnly: boolean,
    difficulty: number,
    requiredBrandProducts: BrandProduct[],
    postRequirements: PostRequirement[],
    challengePostRequirements: PostRequirement[],
    newTags?: Set<string>
  ) => {
    setNewChallenge(
      getChallenge(
        getValues("description"),
        getValues("audienceType"),
        brandId,
        isCommercialSoundsOnly,
        difficulty,
        requiredBrandProducts,
        postRequirements,
        challengePostRequirements,
        newTags,
        getValues("exampleUrl"),
        getValues("title")
      )
    );
  };

  const brandClicked = (brandOption: OptionInterface) => {
    setSelectedBrandId(brandOption.id);

    const newTags = new Set<string>();
    if (brandOption.hashtagRequirement) {
      // Break down by spaces
      brandOption.hashtagRequirement.split(" ").forEach((word) => {
        newTags.add(word);
      });
    }
    if (brandOption.kaleHashtags) {
      // Break down by spaces
      brandOption.kaleHashtags.split(" ").forEach((word) => {
        newTags.add(word);
      });
    }
    setTags(newTags);

    setBrandAudience((prevData) => {
      return [brandOption];
    });
  };

  // const audienceBrandClicked = (brandOption: OptionInterface) => {
  //   setBrandAudience((prevData) => {
  //     const newOptions = new Set(prevData);
  //     newOptions.add(brandOption);
  //     return Array.from(newOptions);
  //   });
  // };

  // const audienceBrandCategoryClicked = (brandOption: OptionInterface) => {
  //   setBrandCategoriesAudience((prevData) => {
  //     const newOptions = new Set(prevData);
  //     newOptions.add(brandOption);
  //     return Array.from(newOptions);
  //   });
  // };

  useEffect(() => {
    getAllBrandCategories().then((brandCategories) => {
      setBrandCategories(
        brandCategories.map((category) => {
          return {
            id: category.id,
            name: category.name,
            imageUrl: null,
          };
        })
      );
    });
    getAllBrands(BRANDS, true).then((brands) => {
      setBrands(brands);
      setBrandOptions(
        brands.map((brand) => {
          return {
            id: brand.id,
            name: brand.name,
            imageUrl: brand.logoUrl,
            hashtagRequirement: brand.hashtagRequirement,
            kaleHashtags: brand.kaleHashtagRequirements?.join(" "),
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    let commercialSoundsOnly = isCommercialSoundsOnly;
    let postRequirements = [];
    if (selectedBrandId) {
      setError(null);
      const selectedBrand = brands.find(
        (brand) => brand.id === selectedBrandId
      );

      commercialSoundsOnly =
        selectedBrand?.shouldRequireCommercialSound === true;
      setIsCommercialSoundsOnly(commercialSoundsOnly);

      setIsChallengeReviewRequired(
        selectedBrand?.isChallengeReviewRequired === true
      );

      postRequirements = selectedBrand?.postRequirements ?? [];
      setPostRequirements(postRequirements);
    }

    const requiredBrandProducts = selectedBrandProduct
      ? [selectedBrandProduct]
      : [];
    updateChallenge(
      selectedBrandId,
      commercialSoundsOnly,
      difficulty,
      requiredBrandProducts,
      postRequirements,
      challengePostRequirements,
      tags
    );
  }, [selectedBrandId]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "exampleUrl" || name === "description" || name === "title") {
        const requiredBrandProducts = selectedBrandProduct
          ? [selectedBrandProduct]
          : [];
        updateChallenge(
          selectedBrandId,
          isCommercialSoundsOnly,
          difficulty,
          requiredBrandProducts,
          postRequirements,
          challengePostRequirements,
          tags
        );
      }

      // if (name === "audienceType") {
      //   if (value.audienceType === "TARGETED") {
      //     setShowAudience(true);
      //   } else {
      //     setShowAudience(false);
      //   }
      // }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    selectedBrandId,
    tags,
    difficulty,
    isCommercialSoundsOnly,
    selectedBrandProduct,
    postRequirements,
  ]);

  useEffect(() => {
    const requiredBrandProducts = selectedBrandProduct
      ? [selectedBrandProduct]
      : [];
    updateChallenge(
      selectedBrandId,
      isCommercialSoundsOnly,
      difficulty,
      requiredBrandProducts,
      postRequirements,
      challengePostRequirements,
      tags
    );
  }, [
    selectedBrandId,
    isCommercialSoundsOnly,
    difficulty,
    tags,
    selectedBrandProduct,
    postRequirements,
    challengePostRequirements,
  ]);

  const submit: SubmitHandler<NewChallengeFormInputs> = async (
    data: NewChallengeFormInputs
  ) => {
    setIsLoading(true);
    try {
      const exampleUrl = getValues("exampleUrl");
      const result = await createChallenge({
        variables: {
          brandId: selectedBrandId,
          description: data.description,
          tagRequirements: Array.from(tags).join(" "),
          audienceType: "ALL",
          brandIds: brandAudience.map((option) => option.id),
          brandCategoryIds: brandCategoriesAudience.map((option) => option.id),
          creatorIds: Array.from(creatorsAudience),
          exampleUrl: exampleUrl,
          difficulty: difficulty,
          isCommercialSoundsOnly: isCommercialSoundsOnly,
          startDate: getRoundedDate(startDate)?.toString(),
          endDate: getRoundedDate(endDate)?.toString(),
          categoryId: challengeCategory?.id,
          brandProductId: selectedBrandProduct?.id,
          challengePostRequirements: challengePostRequirements?.map(
            (postRequirement) => postRequirement.id
          ),
          title: data.title,
        },
      });
      console.log(result);
      onSubmit(result.data.createChallenge as Challenge);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <form className="grid h-full content-between space-y-2">
      <ChallengeFormDataComponent
        brandOptions={brandOptions}
        brands={brands}
        selectedBrandId={selectedBrandId}
        brandClicked={brandClicked}
        isChallengeReviewRequired={isChallengeReviewRequired}
        challengeCategory={challengeCategory}
        setChallengeCategory={setChallengeCategory}
        register={register}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        difficulty={difficulty}
        setDifficulty={setDifficulty}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        tags={tags}
        setTags={setTags}
        isCommercialSoundsOnly={isCommercialSoundsOnly}
        setIsCommercialSoundsOnly={setIsCommercialSoundsOnly}
        selectedBrandProduct={selectedBrandProduct}
        setSelectedBrandProduct={setSelectedBrandProduct}
        challengePostRequirements={challengePostRequirements}
        setChallengePostRequirements={setChallengePostRequirements}
        setError={setError}
        isEditForm={false}
        shouldShowCommercialSoundsInput={true}
      />

      {/* Audience */}
      {/* <div className="mt-2 rounded-md border px-2 py-4">
          <h2 className="text-lg font-bold">Audience</h2>
          <p className="text-sm text-gray-400">
            Select the targeting for the challenge.
          </p>
          <label
            htmlFor="audienceType"
            className="block text-sm font-medium text-gray-700"
          >
            Audience Type
          </label>
          <select
            id="audienceType"
            name="audienceType"
            {...register("audienceType")}
            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue="ALL"
          >
            <option value={"ALL"}>Everyone</option>
            <option value={"TARGETED"}>Targeted</option>
          </select>
          {showAudience && (
            <>
              <div className="mt-2 flex flex-col">
                <Dropdown
                  options={brandOptions}
                  activeOptionId={null}
                  optionClicked={audienceBrandClicked}
                  label={
                    <>
                      <p className="text-base">Brand:</p>
                      <p className="text-xs text-gray-400">
                        This challenge will be visible to creators that belong
                        to any of the brands chosen below.
                      </p>
                    </>
                  }
                  placeholderImage={
                    <BuildingStorefrontIcon className="h-6 w-6 flex-shrink-0 rounded-full" />
                  }
                />
                <div className="mt-2 flex flex-wrap">
                  {Array.from(brandAudience).map((brandOption) => {
                    return (
                      <div
                        key={brandOption.id}
                        className="mb-2 mr-2 inline-flex items-center rounded-full bg-kale-mint-500 py-1 pl-4 pr-2 text-sm font-medium text-kale-green-500"
                      >
                        <div className="mr-1 flex flex-col">
                          <p className="font-bold">{brandOption.name}</p>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            setBrandAudience((prevData) => {
                              const newOptions = new Set(prevData);
                              newOptions.delete(brandOption);
                              return Array.from(newOptions);
                            });
                          }}
                          className="ml-0.5 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-kale-green-400 hover:bg-kale-green-200 hover:text-kale-green-500 focus:bg-kale-green-500 focus:text-white focus:outline-none"
                        >
                          <span className="sr-only">
                            Remove {brandOption.name}
                          </span>
                          <XMarkIcon className="p-1" />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-2 flex flex-col">
                <Dropdown
                  options={brandCategories}
                  activeOptionId={null}
                  optionClicked={audienceBrandCategoryClicked}
                  label={
                    <>
                      <p className="text-base">Brand Categories:</p>
                      <p className="text-xs text-gray-400">
                        This challenge will be visible to creators that belong
                        to any of the brand categories chosen below.
                      </p>
                    </>
                  }
                />
                <div className="mt-2 flex flex-wrap">
                  {Array.from(brandCategoriesAudience).map((brandOption) => {
                    return (
                      <div
                        key={brandOption.id}
                        className="mb-2 mr-2 inline-flex items-center rounded-full bg-kale-mint-500 py-1 pl-4 pr-2 text-sm font-medium text-kale-green-500"
                      >
                        <div className="mr-1 flex flex-col">
                          <p className="font-bold">{brandOption.name}</p>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            setBrandCategoriesAudience((prevData) => {
                              const newOptions = new Set(prevData);
                              newOptions.delete(brandOption);
                              return Array.from(newOptions);
                            });
                          }}
                          className="ml-0.5 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-kale-green-400 hover:bg-kale-green-200 hover:text-kale-green-500 focus:bg-kale-green-500 focus:text-white focus:outline-none"
                        >
                          <span className="sr-only">
                            Remove {brandOption.name}
                          </span>
                          <XMarkIcon className="p-1" />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-2 flex flex-col">
                <p className="text-base">Creator Ids:</p>
                <label
                  htmlFor="newCreatorId"
                  className="block text-xs text-gray-400"
                >
                  Add a creator ids that will be targeted to this challenge.
                </label>
                <div className="flex w-full">
                  <div className="mt-1 w-full">
                    <input
                      {...register("newCreatorId")}
                      type="text"
                      name="newCreatorId"
                      id="newCreatorId"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="CREATOR_ID"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      const newCreatorId = watch("newCreatorId");
                      const newCreatorIds = new Set(creatorsAudience);
                      newCreatorIds.add(newCreatorId);
                      setCreatorsAudience(newCreatorIds);
                      setValue("newCreatorId", null);
                    }}
                    className="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-60"
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="mt-4 flex flex-wrap space-x-2">
                {Array.from(creatorsAudience).map((creatorId) => {
                  return (
                    <div
                      key={creatorId}
                      className="mb-2 inline-flex items-center rounded-full bg-kale-mint-500 py-1 pl-4 pr-2 text-sm font-medium text-kale-green-500"
                    >
                      <div className="mr-1 flex flex-col">
                        <p className="font-bold">{creatorId}</p>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          console.log(creatorsAudience);
                          const newCreatorIds = new Set(creatorsAudience);
                          newCreatorIds.delete(creatorId);
                          setCreatorsAudience(newCreatorIds);
                        }}
                        className="ml-0.5 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-kale-green-400 hover:bg-kale-green-200 hover:text-kale-green-500 focus:bg-kale-green-500 focus:text-white focus:outline-none"
                      >
                        <span className="sr-only">Remove {creatorId}</span>
                        <XMarkIcon className="p-1" />
                      </button>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div> */}
      <div>
        <div className="flex justify-end space-x-2">
          {isLoading && (
            <div className="flex flex-wrap content-center justify-center">
              <Spinner size="h-6 w-6" />
            </div>
          )}
          <div className="flex justify-end">
            <Button
              type="button"
              onClick={handleSubmit(submit)}
              disabled={isLoading}
              color="indigo"
              className="hover:cursor-pointer"
            >
              Create Challenge
            </Button>
          </div>
        </div>
        <div className="mt-2 flex justify-end">
          <p className="font-bold text-red-500">{error}</p>
        </div>
      </div>
    </form>
  );
}
