import React, { useEffect, useState } from "react";
import { BonsaiQueue } from "../BonsaiModels";
import { BonsaiActionPanel } from "../BonsaiContainer";
import { Fieldset, Label } from "../../catalyst/fieldset";
import {
  Checkbox,
  CheckboxField,
  CheckboxGroup,
} from "../../catalyst/checkbox";
import { Strong, Text, TextLink } from "../../catalyst/text";
import { Tooltip } from "flowbite-react";
import {
  BonsaiPanel,
  BonsaiVideoPanel,
  BonsaiContextLinkType,
  bonsaiContextLinkTypeForExternalUrl,
  timeDifferenceForDate,
} from "@kalecard/common";
import { Listbox, ListboxLabel, ListboxOption } from "../../catalyst/listbox";
import { Badge } from "../../catalyst/badge";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../../catalyst/dialog";
import { Button } from "../../catalyst/button";

enum PostScoringOutcome {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

const REQUIREMENTS_NOT_MET_REJECTION_REASON = "REQUIREMENTS_NOT_MET";

interface RubricItem {
  id: number;
  name: string;
  description: string;
  points: number;
}

interface RequirementItem {
  id: string;
  name: string;
}

interface RejectionReason {
  id: string;
  name: string;
}

interface PostScoringInput {
  creatorId: number;
  postId: number;
  postCaption: string;
  postExternalUrl: string;
  postUrl: string;
  postedAt: number;
  postedBy: string;

  challengeId: number;
  challengeDescription: string;
  challengeExampleUrl?: string;
  challengeDifficulty: number;
  isChallengeCommercialSoundsOnly?: boolean;

  rubric: RubricItem[];
  requirements: RequirementItem[];
  rejectionReasons: RejectionReason[];
}

interface PostScoringOutput {
  outcome: PostScoringOutcome;
  rejectionReasonId?: string;
  alternativeChallengeId?: number;
  markedRubricIds: number[];
  unmetRequirements?: string[];
}

interface PostScoringToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: PostScoringOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: PostScoringInput;
}

export function PostScoringTool(props: PostScoringToolProps) {
  const [checked, setChecked] = useState<Set<number>>(new Set());
  const [selectedOutcome, setSelectedOutcome] =
    useState<PostScoringOutcome>(undefined);
  const [rejectionReason, setRejectionReason] = useState<string>(undefined);
  const [requirementsDialogOpen, setRequirementsDialogOpen] = useState(false);

  const links = [
    {
      type: bonsaiContextLinkTypeForExternalUrl(props.input.postExternalUrl),
      url: props.input.postExternalUrl,
    },
    {
      type: BonsaiContextLinkType.CREATOR,
      url: `/creators/${props.input.creatorId}/messages`,
    },
  ];

  const leafString = (count: number) => {
    return "🥬 ".repeat(count);
  };

  const rubric = props.input.rubric.sort((a, b) => b.points - a.points);

  return (
    <>
      <div className="flex flex-row gap-4">
        <div className={"flex-1"}>
          <BonsaiPanel>
            <div className="flex h-full flex-col justify-between p-4">
              <div className="flex-row justify-center space-y-4">
                <div className="text-md text-center font-semibold text-slate-700">
                  Scoring Information
                </div>
                <Text>
                  <Strong>Challenge ({props.input.challengeId}):</Strong>{" "}
                  {props.input.challengeDescription}
                </Text>
                <Text>
                  <Strong>Difficulty: </Strong>{" "}
                  {leafString(props.input.challengeDifficulty)}
                </Text>
                <div>
                  <Text>
                    <Strong>Requirements: </Strong>
                  </Text>
                  <ul>
                    {props.input.requirements.map((requirement) => (
                      <li key={requirement.id}>
                        <Text>• {requirement.name}</Text>
                      </li>
                    ))}
                  </ul>
                </div>
                {props.input.challengeExampleUrl && (
                  <Text>
                    <Strong>
                      <TextLink
                        href={props.input.challengeExampleUrl}
                        target="_blank"
                      >
                        Example Post
                      </TextLink>
                    </Strong>
                  </Text>
                )}
                {props.input.isChallengeCommercialSoundsOnly === true && (
                  <Badge color="yellow">Commercial Sounds Only</Badge>
                )}
                <div />
                <div className={"h-0.5"} />
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm font-semibold text-gray-500">
                      Post Details
                    </span>
                  </div>
                </div>
                <Text>
                  <Strong>Caption:</Strong> {props.input.postCaption}
                </Text>
                <Text>
                  Posted {timeDifferenceForDate(props.input.postedAt)} by{" "}
                  {props.input.postedBy}
                </Text>
              </div>
            </div>
          </BonsaiPanel>
        </div>
        <div className={"flex-1"}>
          <BonsaiVideoPanel
            source={props.input.postUrl}
            links={links}
            autoPlay={false}
          />
        </div>
        <div className={"flex-1"}>
          <BonsaiPanel>
            <div className="h-full flex-row justify-center p-4">
              <div className="flex h-full flex-col space-y-4">
                <div className="text-md text-center font-semibold text-slate-700">
                  Rubric
                </div>
                <ScoringRubric
                  rubric={rubric}
                  onChecked={(id) => {
                    let newChecked = new Set(checked);
                    if (newChecked.has(id)) {
                      newChecked.delete(id);
                    } else {
                      newChecked.add(id);
                    }
                    setChecked(newChecked);
                  }}
                  checked={checked}
                  disabled={
                    selectedOutcome === PostScoringOutcome.REJECTED ||
                    props.readOnly
                  }
                />
              </div>
            </div>
          </BonsaiPanel>
        </div>
      </div>

      {!props.readOnly && (
        <BonsaiActionPanel
          submitDisabled={
            selectedOutcome === undefined ||
            (selectedOutcome === PostScoringOutcome.REJECTED &&
              !rejectionReason) ||
            (selectedOutcome === PostScoringOutcome.ACCEPTED &&
              checked.size === 0)
          }
          onSubmit={() => {
            // If the rejection reason is REQUIREMENTS_NOT_MET, we show the requirements dialog instead, and the onComplete will happen inside there
            if (
              selectedOutcome === PostScoringOutcome.REJECTED &&
              rejectionReason === REQUIREMENTS_NOT_MET_REJECTION_REASON
            ) {
              setRequirementsDialogOpen(true);
              return;
            }
            props.onComplete({
              outcome: selectedOutcome,
              rejectionReasonId: rejectionReason,
              markedRubricIds: rejectionReason ? null : Array.from(checked),
            });

            setRejectionReason(null);
            setSelectedOutcome(undefined);
            setChecked(new Set());
          }}
          taskDescription={props.queue.taskDescription}
          taskId={props.taskId}
        >
          <OutcomeControls
            selected={selectedOutcome}
            onSelected={(outcome) => {
              setSelectedOutcome(outcome);
              if (outcome === PostScoringOutcome.ACCEPTED) {
                setRejectionReason(null);
              }
            }}
            rejectionReason={rejectionReason}
            onRejectionReason={setRejectionReason}
            rejectionReasons={props.input.rejectionReasons}
          />
          <RequirementsDialog
            requirements={props.input.requirements}
            isOpen={requirementsDialogOpen}
            onClose={() => setRequirementsDialogOpen(false)}
            onComplete={(outcome: PostScoringOutput) => {
              props.onComplete(outcome);

              // Reset the state of this PostScoringTool after the requirements dialog submits
              setRejectionReason(null);
              setSelectedOutcome(undefined);
              setChecked(new Set());
              setRequirementsDialogOpen(false);
            }}
          />
        </BonsaiActionPanel>
      )}
    </>
  );
}

interface ScoringRubricProps {
  rubric: RubricItem[];
  onChecked: (rubricId: number) => void;
  checked: Set<number>;
  disabled: boolean;
}

function ScoringRubric(props: ScoringRubricProps) {
  const [points, setPoints] = useState(0);

  useEffect(() => {
    const selectedPoints = Array.from(props.checked)
      .map((checkedId) => {
        return props.rubric.find((item) => item.id === checkedId).points;
      })
      .reduce((a, b) => a + b, 0);
    setPoints(selectedPoints);
  }, [props.checked]);

  return (
    <div className="flex h-full flex-col justify-between">
      <Fieldset>
        <CheckboxGroup className="space-y-0">
          {props.rubric.map((requirement) => (
            <CheckboxField key={requirement.id}>
              <Checkbox
                name="requirements"
                color="green"
                disabled={props.disabled}
                checked={props.checked.has(requirement.id)}
                onChange={() => props.onChecked(requirement.id)}
              />
              <Tooltip
                content={
                  <div className="text-wrap">{requirement.description}</div>
                }
                placement="top"
              >
                <Label>{requirement.name}</Label>
              </Tooltip>
            </CheckboxField>
          ))}
        </CheckboxGroup>
      </Fieldset>
      <div className="text-sm font-semibold text-slate-900">
        Total Points: {points / 100}
      </div>
    </div>
  );
}

interface OutcomeControlsProps {
  selected?: PostScoringOutcome;
  onSelected: (outcome: PostScoringOutcome) => void;
  rejectionReason?: string;
  onRejectionReason: (reason: string) => void;
  rejectionReasons: RejectionReason[];
}

function OutcomeControls(props: OutcomeControlsProps) {
  return (
    <>
      <span className="isolate mr-1 inline-flex rounded-md shadow-sm">
        <button
          type="button"
          className={`relative inline-flex items-center rounded-l-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
            props.selected === PostScoringOutcome.ACCEPTED
              ? "bg-green-500 text-white"
              : "bg-white hover:bg-gray-50"
          }`}
          onClick={() => props.onSelected(PostScoringOutcome.ACCEPTED)}
        >
          Accept
        </button>
        <button
          type="button"
          className={`relative -ml-px inline-flex items-center rounded-r-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
            props.selected === PostScoringOutcome.REJECTED
              ? "bg-red-600 text-white"
              : "bg-white hover:bg-gray-50"
          }`}
          onClick={() => props.onSelected(PostScoringOutcome.REJECTED)}
        >
          Reject
        </button>
      </span>
      {props.selected === PostScoringOutcome.REJECTED && (
        <Listbox
          name="rejectionReason"
          placeholder="Set Rejection Reason"
          value={props.rejectionReason}
          onChange={props.onRejectionReason}
          anchorTo="top start"
        >
          {props.rejectionReasons?.map((reason) => (
            <ListboxOption key={reason.id} value={reason.id}>
              <ListboxLabel>{reason.name}</ListboxLabel>
            </ListboxOption>
          ))}
        </Listbox>
      )}
    </>
  );
}

interface RequirementsDialogProps {
  requirements: RequirementItem[];
  isOpen: boolean;
  onClose: () => void;
  onComplete: (outcome: PostScoringOutput) => void;
}

function RequirementsDialog(props: RequirementsDialogProps) {
  const [checked, setChecked] = useState<Set<string>>(new Set());
  const translateCheckedToRequirements = (checked: Set<string>) => {
    return Array.from(checked).map((id) => {
      return props.requirements.find((requirement) => requirement.id === id)
        .name;
    });
  };
  return (
    <Dialog onClose={props.onClose} open={props.isOpen}>
      <DialogTitle>
        Which requirements were not met? (Select all that apply):
      </DialogTitle>
      <DialogDescription></DialogDescription>
      <DialogBody>
        {props.requirements.map((requirement) => (
          <CheckboxField key={requirement.id}>
            <Checkbox
              name="requirements"
              color="green"
              checked={checked.has(requirement.id)}
              onChange={() => {
                let newChecked = new Set(checked);
                if (newChecked.has(requirement.id)) {
                  newChecked.delete(requirement.id);
                } else {
                  newChecked.add(requirement.id);
                }
                setChecked(newChecked);
              }}
            />
            <Label>{requirement.name}</Label>
          </CheckboxField>
        ))}
      </DialogBody>
      <DialogActions>
        <Button
          plain
          onClick={() => {
            props.onClose();
            setChecked(new Set());
          }}
        >
          Cancel
        </Button>
        <Button
          color="green"
          onClick={() => {
            props.onComplete({
              outcome: PostScoringOutcome.REJECTED,
              rejectionReasonId: REQUIREMENTS_NOT_MET_REJECTION_REASON,
              markedRubricIds: null,
              unmetRequirements: translateCheckedToRequirements(checked),
            });
            setChecked(new Set());
          }}
          disabled={checked.size === 0}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
