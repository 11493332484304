import { Brand, Spinner, numberWithCommas } from "@kalecard/common";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useMutation } from "@apollo/client";
import { BRAND_TRANSACTION } from "../../graphql/mutations";
import { useHistory } from "react-router-dom";
import WhitelistRequestDropdown from "../WhitelistRequestsDropdown";

interface BrandTransactionInputs {
  amount: number;
  transactionType: string;
  stripePaymentIntentId?: string;
  description?: string;
  postURL?: string;
  paymentMethod?: string;
}

const paymentMethods = [
  { name: "Charge credit card", value: "CREDIT_CARD" },
  { name: "Pull from content budget", value: "CONTENT_BUDGET" },
  { name: "Send invoice for new transaction", value: "INVOICE" },
  { name: "Record transaction for pre-charged invoice.", value: "RECORD_ONLY" },
];

export default function BrandTransactionForm({ brand }: { brand: Brand }) {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [startDate, setStartDate] = useState(new Date());
  const [successMessage, setSuccessMessage] = useState<string>(null);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [whitelistRequestId, setWhitelistRequestId] = useState(null);
  const { reset, register, handleSubmit, watch, setValue } =
    useForm<BrandTransactionInputs>({
      defaultValues: {
        amount: brand.platformFee,
        transactionType: "PLATFORM_FEE",
      },
    });
  const [brandTransactionMutation] = useMutation(BRAND_TRANSACTION);
  const history = useHistory();

  const onSubmit = async (data: BrandTransactionInputs) => {
    console.log(data);
    setSuccessMessage(null);
    setErrorMessage(null);
    setIsLoading(true);
    let recordOnly = false;
    if (data.paymentMethod === "RECORD_ONLY") {
      recordOnly = true;
      data.paymentMethod = "INVOICE"; // doesn't matter really we only support invoice on the backend
    }
    const params = {
      amount: data.amount,
      transactionType: data.transactionType,
      brandId: brand.id,
      date: startDate.getTime().toString(),
      stripePaymentIntentId: data.stripePaymentIntentId,
      description: data.description,
      postURL: data.postURL,
      whitelistRequestId: whitelistRequestId,
      paymentMethod: data.paymentMethod,
      recordOnly: recordOnly,
    };

    try {
      await brandTransactionMutation({
        variables: params,
      });
      reset();
      setStartDate(new Date());
      setSuccessMessage(
        `Successfully ran ${data.transactionType} for $${numberWithCommas(
          data.amount
        )} for ${brand.name}`
      );
      history.push(`/brands/${brand.id}/transactions`);
    } catch (error) {
      console.error(error);
      setErrorMessage(
        `Failed to run ${data.transactionType} for ${brand.name}, please try again.`
      );
    }
    setIsLoading(false);
  };

  const configuredPaymentMethod = brand.paymentMethodType
    ? brand.paymentMethodType
    : paymentMethods[0].value;
  const brandPaymentMethod =
    "(currently configured: " +
    (brand.paymentMethodType ? brand.paymentMethodType : "None") +
    ")";
  const invoiceNotice =
    configuredPaymentMethod === "INVOICE"
      ? "NOTE: The invoice option will now send the customer a new invoice for a new transaction instead of recording an existing transaction!"
      : "";

  return (
    <form className="rounded-md border-2 p-2" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex w-full flex-col space-y-4">
        <h1>Brand transaction</h1>
        {/* Type of charge this is */}
        <div className="sm:col-span-3">
          <label
            htmlFor="transactionType"
            className="block text-sm font-medium text-gray-700"
          >
            What is the transaction for?
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <select
              id="transactionType"
              name="transactionType"
              {...register("transactionType", {
                onChange: (e) => {
                  if (e.target.value === "PLATFORM_FEE") {
                    setValue("amount", brand.platformFee);
                  } else if (e.target.value === "CONTENT_BUDGET") {
                    setValue("amount", brand.contentBudgetMax);
                  }
                },
              })}
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value={"PLATFORM_FEE"}>Platform Fee</option>
              <option value={"CONTENT_BUDGET"}>Content Budget</option>
              <option value={"KALE_CREDIT"}>Kale Credit</option>
              <option value={"MISCELLANEOUS_CHARGE"}>
                Miscellaneous Charge
              </option>
              <option value={"CONTENT_RIGHTS_CHARGE"}>Content Rights</option>
              <option value={"WHITELISTING_CHARGE"}>Whitelisting Charge</option>
              <option value={"SETUP_FEE"}>Setup Fee</option>
              <option value={"CREATOR_SURVEY"}>Creator Survey</option>
            </select>
          </div>
        </div>
        {/* Amount to use for the transaction */}
        <div className="sm:col-span-3">
          <label
            htmlFor="amount"
            className="block text-sm font-medium text-gray-700"
          >
            Amount
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              onWheel={(e) => (e.target as HTMLElement).blur()}
              step={0.01}
              {...register("amount")}
              name="amount"
              id="amount"
              className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              aria-describedby="amount-currency"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="amount-currency">
                USD
              </span>
            </div>
          </div>
        </div>
        {/* What month should this charge be for? */}
        {(watch("transactionType") === "PLATFORM_FEE" ||
          watch("transactionType") === "CONTENT_BUDGET") && (
          <div className="sm:col-span-3">
            <label
              htmlFor="month"
              className="block text-sm font-medium text-gray-700"
            >
              What date is this charge for (can be post-dated)?
            </label>
            <DatePicker
              className="mt-1 rounded-md border-gray-300 text-sm shadow-sm"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/dd/yyyy"
              showMonthDayYearPicker
            />
          </div>
        )}
        {/* A description */}
        {watch("transactionType") === "MISCELLANEOUS_CHARGE" && (
          <div className="flex flex-col">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              <p>A description to show the brand, what the charge was for?</p>
            </label>
            <textarea
              className="w-full rounded-md border-gray-300 px-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              name="description"
              placeholder="What is this charge for?"
              id="description"
              {...register("description")}
            />
          </div>
        )}
        {/* The post URL */}
        {watch("transactionType") === "CONTENT_RIGHTS_CHARGE" && (
          <div className="flex flex-col">
            <label
              htmlFor="postURL"
              className="block text-sm font-medium text-gray-700"
            >
              <p>The post's external URL:</p>
            </label>
            <textarea
              className="w-full rounded-md border-gray-300 px-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              name="postURL"
              placeholder="https://www.tiktok.com/@username/video/123456"
              id="postURL"
              {...register("postURL")}
            />
            <p className="pt-2 text-xs font-medium text-gray-700">
              Please follow the following format: <br />
              TikTok - https://www.tiktok.com/@username/video/123456 <br />
              Instagram - https://www.instagram.com/reel/ABCdeEFG/
            </p>
          </div>
        )}
        {/* Which whitelisting request is it? */}
        {watch("transactionType") === "WHITELISTING_CHARGE" && (
          <WhitelistRequestDropdown
            brandId={brand.id}
            requestId={whitelistRequestId}
            states={["READY", "IN_REVIEW", "IN_PROGRESS"]}
            isBrandView={true}
            setRequestId={setWhitelistRequestId}
          />
        )}
        {watch("transactionType") !== "KALE_CREDIT" &&
          watch("transactionType") !== "REFUND" && (
            <div className="flex flex-col">
              <label
                htmlFor="paymentMethod"
                className="block text-sm font-medium text-gray-700"
              >
                <p>Choose a payment method</p>
              </label>
              <label className="block text-sm font-bold text-red-600">
                <p>{invoiceNotice}</p>
              </label>
              <select
                id="paymentMethod"
                name="paymentMethod"
                {...register("paymentMethod")}
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                defaultValue={configuredPaymentMethod}
              >
                {paymentMethods.map(
                  (paymentMethod) =>
                    (paymentMethod.value === "INVOICE" ||
                      paymentMethod.value === "CONTENT_BUDGET" ||
                      paymentMethod.value === "RECORD_ONLY") && (
                      <option value={paymentMethod.value}>
                        {paymentMethod.name}
                      </option>
                    )
                )}
              </select>
            </div>
          )}
      </div>
      <div className="pt-5">
        <div className="flex items-center justify-end">
          {isLoading && <Spinner size={"h-6 w-6"} />}
          <button
            disabled={isLoading}
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-300"
          >
            Submit
          </button>
        </div>
        <div className="flex items-center justify-end">
          {successMessage && (
            <p className="text-sm font-bold text-green-500">{successMessage}</p>
          )}
          {errorMessage && (
            <p className="text-sm font-bold text-red-500">{errorMessage}</p>
          )}
        </div>
      </div>
    </form>
  );
}
