import { gql } from "../__generated__";

export const UPDATE_FACEBOOK_ACCESS_TOKEN = gql(`
  mutation UpdateFacebookAccessToken(
    $accessToken: String!
    $scopes: String!
    $shopId: ID!
  ) {
    updateFacebookAccessToken(
      accessToken: $accessToken
      scopes: $scopes
      shopId: $shopId
    )
  }
`);
export const MESSAGE_CREATOR = gql(`
  mutation MessageCreator($userId: ID!, $text: String!) {
    messageCreator(userId: $userId, text: $text)
  }
`);
export const UNDO_DEAL = gql(`
  mutation UndoDeal($dealId: ID!, $shouldRefund: Boolean) {
    undoDeal(dealId: $dealId, shouldRefund: $shouldRefund)
  }
`);
export const GET_UPLOAD_SIGNED_URL = gql(`
  mutation GetUploadSignedUrl(
    $mimeType: String!
    $uploadType: String!
    $metadata: UploadRequestMetadata
  ) {
    getUploadSignedUrl(
      mimeType: $mimeType
      uploadType: $uploadType
      metadata: $metadata
    ) {
      mimeType
      uploadType
      bucketPath
      signedUrl
    }
  }
`);

export const DOWNLOAD_MEDIA = gql(`
  mutation DownloadMedia($postId: ID!) {
    downloadMedia(postId: $postId)
  }
`);

export const SET_POST_QUALITY = gql(`
  mutation SetPostQuality(
    $postId: ID!
    $score: Int!
    $isSecondary: Boolean
    $dealId: ID
  ) {
    setPostQuality(
      postId: $postId
      score: $score
      isSecondary: $isSecondary
      dealId: $dealId
    )
  }
`);

export const ADD_DEAL_TO_POST = gql(`
  mutation AddDealToPost($postId: ID!, $dealId: ID!) {
    addDealToPost(postId: $postId, dealId: $dealId)
  }
`);

export const REFRESH_USER_SOCIAL_DATA = gql(`
  mutation RefreshUserSocialData($userId: ID!) {
    refreshUserSocialData(userId: $userId)
  }
`);

export const REMOVE_EXTERNAL_USER = gql(`
  mutation RemoveExternalUser($externalUserId: ID!) {
    removeExternalUser(externalUserId: $externalUserId)
  }
`);
export const NEW_POST = gql(`
  mutation NewPost(
    $userId: ID!
    $externalPlatform: String!
    $caption: String
    $productType: String!
    $mediaType: String!
    $externalPermalink: String!
    $username: String!
  ) {
    newPost(
      userId: $userId
      externalPlatform: $externalPlatform
      caption: $caption
      productType: $productType
      mediaType: $mediaType
      externalPermalink: $externalPermalink
      username: $username
    )
  }
`);

export const UPDATE_POST_CHILD = gql(`
  mutation UpdatePostChild($childId: ID!, $isBrandPost: Boolean!) {
    updatePostChild(childId: $childId, isBrandPost: $isBrandPost)
  }
`);

export const UPDATE_POST_METRICS = gql(`
  mutation UpdatePostMetrics(
    $postId: ID!
    $impressions: String!
    $likes: String!
    $comments: String!
    $isFirstMetricsRecording: Boolean!
  ) {
    updatePostMetrics(
      postId: $postId
      impressions: $impressions
      likes: $likes
      comments: $comments
      isFirstMetricsRecording: $isFirstMetricsRecording
    )
  }
`);

export const CREATE_CHALLENGE = gql(`
  mutation CreateChallenge(
    $brandId: ID!
    $description: String!
    $tagRequirements: String
    $audienceType: String!
    $brandIds: [ID!]
    $brandCategoryIds: [ID!]
    $creatorIds: [ID!]
    $exampleUrl: String
    $difficulty: Int
    $isCommercialSoundsOnly: Boolean
    $startDate: String
    $endDate: String
    $categoryId: ID!
    $brandProductId: ID
    $challengePostRequirements: [ID!]
    $title: String!
  ) {
    createChallenge(
      brandId: $brandId
      description: $description
      tagRequirements: $tagRequirements
      audienceType: $audienceType
      brandIds: $brandIds
      brandCategoryIds: $brandCategoryIds
      creatorIds: $creatorIds
      exampleUrl: $exampleUrl
      difficulty: $difficulty
      isCommercialSoundsOnly: $isCommercialSoundsOnly
      startDate: $startDate
      endDate: $endDate
      categoryId: $categoryId
      brandProductId: $brandProductId
      challengePostRequirements: $challengePostRequirements
      title: $title
    ) {
      id
      description
      mentionRequirements
      tagRequirements
      createdAt
      state
      brand {
        id
        name
        logoUrl
        postRequirements {
          id
          requirement
          emoji
        }
      }
      audienceType
      audience {
        brands {
          id
          name
        }
        brandCategories {
          id
          name
        }
        creators {
          id
          name
        }
      }
      exampleUrl
      difficulty
      totalPosts
      totalClaims
      postRate
      startDate
      endDate
      isCommercialSoundsOnly
      category {
        id
        name
      }
      requiredBrandProducts {
        id
        externalUrl
      }
      postRequirements {
        id
        requirement
        emoji
      }
      title
    }
  }
`);

export const UPDATE_CHALLENGE = gql(`
  mutation UpdateChallenge(
    $challengeId: ID!
    $description: String
    $tagRequirements: String
    $state: String
    $audienceType: String
    $brandIds: [ID!]
    $brandCategoryIds: [ID!]
    $creatorIds: [ID!]
    $exampleUrl: String
    $difficulty: Int
    $startDate: String
    $endDate: String
    $isCommercialSoundsOnly: Boolean
    $brandProductId: ID
    $challengePostRequirements: [ID!]
    $categoryId: ID
    $title: String
  ) {
    updateChallenge(
      challengeId: $challengeId
      description: $description
      tagRequirements: $tagRequirements
      state: $state
      audienceType: $audienceType
      brandIds: $brandIds
      brandCategoryIds: $brandCategoryIds
      creatorIds: $creatorIds
      exampleUrl: $exampleUrl
      difficulty: $difficulty
      startDate: $startDate
      endDate: $endDate
      isCommercialSoundsOnly: $isCommercialSoundsOnly
      brandProductId: $brandProductId
      challengePostRequirements: $challengePostRequirements
      categoryId: $categoryId
      title: $title
    ) {
      id
      description
      mentionRequirements
      tagRequirements
      createdAt
      state
      brand {
        id
        name
        logoUrl
        postRequirements {
          id
          requirement
          emoji
        }
      }
      audienceType
      audience {
        brands {
          id
          name
        }
        brandCategories {
          id
          name
        }
        creators {
          id
          name
        }
      }
      exampleUrl
      difficulty
      totalPosts
      totalClaims
      postRate
      startDate
      endDate
      isCommercialSoundsOnly
      category {
        id
        name
      }
      feedback {
        id
        createdAt
        feedback
        actorName
        status
      }
      isCommercialSoundsOnly
      requiredBrandProducts {
        id
        externalUrl
      }
      postRequirements {
        id
        requirement
        emoji
      }
      title
    }
  }
`);

export const UPDATE_CHALLENGE_RANK = gql(`
  mutation UpdateChallengeRank($challengeId: ID!, $belowChallengeId: ID) {
    updateChallengeRank(
      challengeId: $challengeId
      belowChallengeId: $belowChallengeId
    )
  }
`);

export const TOGGLE_CHALLENGE_STATE = gql(`
  mutation ToggleChallengeState($challengeId: ID!) {
    toggleChallengeState(challengeId: $challengeId)
  }
`);

export const SET_POST_REWARD_CATEGORY = gql(`
  mutation SetPostRewardCategory(
    $postId: ID!
    $rewardCategory: String!
    $mainPostId: ID
  ) {
    setPostRewardCategory(
      postId: $postId
      rewardCategory: $rewardCategory
      mainPostId: $mainPostId
    )
  }
`);

export const REMOVE_POST_REWARD_CATEGORY = gql(`
  mutation RemovePostRewardCategory($postId: ID!) {
    removePostRewardCategory(postId: $postId)
  }
`);

export const APPROVE_RECEIPT_DEAL = gql(`
  mutation ApproveReceiptDeal($dealId: ID!) {
    approveReceiptDeal(dealId: $dealId)
  }
`);

export const UPDATE_CHALLENGE_ID_FOR_DEAL = gql(`
  mutation UpdateChallengeIdForDeal($dealId: ID!, $challengeId: ID!) {
    updateChallengeIdForDeal(dealId: $dealId, challengeId: $challengeId)
  }
`);

export const UPDATE_BRAND_LANDING_PAGE = gql(`
  mutation UpdateBrandLandingPage(
    $brandId: ID!
    $videos: [BrandLandingPageVideoInput]
  ) {
    updateBrandLandingPage(brandId: $brandId, videos: $videos) {
      brand {
        id
      }
    }
  }
`);

export const ADD_NEW_SOCIAL_HANDLE = gql(`
  mutation AddNewSocialHandle(
    $creatorId: ID!
    $socialHandle: String!
    $externalPlatform: String!
  ) {
    addNewSocialHandle(
      creatorId: $creatorId
      socialHandle: $socialHandle
      externalPlatform: $externalPlatform
    )
  }
`);

export const BRAND_TRANSACTION = gql(`
  mutation BrandTransaction(
    $brandId: ID!
    $transactionType: String!
    $amount: Float!
    $date: String
    $stripePaymentIntentId: String
    $description: String
    $postURL: String
    $whitelistRequestId: ID
    $paymentMethod: String
    $recordOnly: Boolean
  ) {
    brandTransaction(
      brandId: $brandId
      transactionType: $transactionType
      amount: $amount
      date: $date
      stripePaymentIntentId: $stripePaymentIntentId
      description: $description
      postURL: $postURL
      whitelistRequestId: $whitelistRequestId
      paymentMethod: $paymentMethod
      recordOnly: $recordOnly
    )
  }
`);

export const CREATOR_TRANSACTION = gql(`
  mutation CreatorTransaction(
    $creatorId: ID!
    $transactionType: String!
    $amount: Int!
    $timestamp: String
    $brandId: ID
    $whitelistRequestId: ID
    $messageToCreator: String
    $postURL: String
  ) {
    creatorTransaction(
      creatorId: $creatorId
      transactionType: $transactionType
      amount: $amount
      timestamp: $timestamp
      brandId: $brandId
      whitelistRequestId: $whitelistRequestId
      messageToCreator: $messageToCreator
      postURL: $postURL
    )
  }
`);

export const TOGGLE_POST_SCORE = gql(`
  mutation TogglePostScore($postId: ID!, $scoreId: ID!, $isSelected: Boolean!) {
    togglePostScore(postId: $postId, scoreId: $scoreId, isSelected: $isSelected)
  }
`);

export const UPSERT_POST_SCORE = gql(`
  mutation UpsertPostScore(
    $id: ID
    $description: String!
    $inverseDescription: String
    $helpText: String!
    $points: Int!
    $isActive: Boolean!
    $brandId: ID
  ) {
    upsertPostScore(
      id: $id
      description: $description
      inverseDescription: $inverseDescription
      helpText: $helpText
      points: $points
      isActive: $isActive
      brandId: $brandId
    ) {
      id
      description
      inverseDescription
      helpText
      points
      isActive
    }
  }
`);

export const UPDATE_CONTENT_WHITELIST = gql(`
  mutation UpdateContentWhitelist(
    $postWhitelistRequestId: ID!
    $state: String
  ) {
    updateContentWhitelist(
      postWhitelistRequestId: $postWhitelistRequestId
      state: $state
    )
  }
`);

export const RESET_CREATOR_BIRTHDATE = gql(`
  mutation ResetCreatorBirthdate($creatorId: ID!) {
    resetCreatorBirthdate(creatorId: $creatorId)
  }
`);

export const UPDATE_CREATOR_ACCOUNT_STATE = gql(`
  mutation UpdateCreatorAccountState(
    $creatorId: ID!
    $accountState: String!
    $accountStateType: String
    $reason: String
  ) {
    updateCreatorAccountState(
      creatorId: $creatorId
      accountState: $accountState
      accountStateType: $accountStateType
      reason: $reason
    )
  }
`);

export const SET_BRAND_PAUSE_CHALLENGES = gql(`
  mutation SetBrandPauseChallenges($brandId: ID!, $shouldPause: Boolean!) {
    setBrandPauseChallenges(brandId: $brandId, shouldPause: $shouldPause)
  }
`);

export const UDPATE_CHALLENGE_FEEDBACK = gql(`
  mutation UpdateChallengeFeedback($challengeFeedbackId: ID!, $status: String) {
    updateChallengeFeedback(
      challengeFeedbackId: $challengeFeedbackId
      status: $status
    )
  }
`);

export const CREATE_OFFER = gql(`
  mutation CreateOffer(
    $brandId: ID!
    $cashbackPercentage: Float!
    $kaleFeePercentage: Float
  ) {
    createOffer(
      brandId: $brandId
      cashbackPercentage: $cashbackPercentage
      kaleFeePercentage: $kaleFeePercentage
    ) {
      id
      brand {
        id
        name
        logoUrl
      }
      cashbackPercentage
      state
      createdAt
      kaleFeePercentage
    }
  }
`);

export const UPDATE_OFFER = gql(`
  mutation UpdateOffer($offerId: ID!, $state: String) {
    updateOffer(offerId: $offerId, state: $state) {
      id
      brand {
        id
        name
        logoUrl
      }
      cashbackPercentage
      state
      createdAt
      kaleFeePercentage
    }
  }
`);

export const UPDATE_POST_REQUIREMENTS_FOR_BRAND = gql(`
  mutation UpdatePostRequirementsForBrand(
    $brandId: ID!
    $postRequirementIds: [ID]
  ) {
    updatePostRequirementsForBrand(
      brandId: $brandId
      postRequirementIds: $postRequirementIds
    )
  }
`);

export const ADD_POST_REQUIREMENT = gql(`
  mutation AddPostRequirement(
    $brandId: ID
    $requirement: String!
    $iconFilename: String
    $emoji: String
  ) {
    addPostRequirement(
      brandId: $brandId
      requirement: $requirement
      iconFilename: $iconFilename
      emoji: $emoji
    ) {
      id
      requirement
      iconFilename
      emoji
    }
  }
`);

export const UPDATE_POST_REQUIREMENT = gql(`
  mutation UpdatePostRequirement(
    $postRequirementId: ID!
    $requirement: String
    $iconFilename: String
    $emoji: String
  ) {
    updatePostRequirement(
      postRequirementId: $postRequirementId
      requirement: $requirement
      iconFilename: $iconFilename
      emoji: $emoji
    ) {
      id
      requirement
      iconFilename
      emoji
    }
  }
`);

export const UPDATE_BRAND_PAYMENT_STRUCTURE = gql(`
  mutation UpdateBrandPaymentStructure(
    $brandPaymentId: ID!
    $minQualityScore: Float
    $kaleOriginationFee: Float
    $creatorOriginationFee: Float
    $kaleCpmFee: Float
    $creatorCpmFee: Float
  ) {
    updateBrandPaymentStructure(
      brandPaymentId: $brandPaymentId
      minQualityScore: $minQualityScore
      kaleOriginationFee: $kaleOriginationFee
      creatorOriginationFee: $creatorOriginationFee
      kaleCpmFee: $kaleCpmFee
      creatorCpmFee: $creatorCpmFee
    ) {
      id
      brand {
        id
      }
      minQualityScore
      productType
      kaleOriginationFee
      creatorOriginationFee
      kaleCpmFee
      creatorCpmFee
    }
  }
`);

export const CREATE_BRAND_PAYMENT_STRUCTURE = gql(`
  mutation CreateBrandPaymentStructure(
    $brandId: ID
    $productType: String
    $minQualityScore: Float!
    $kaleOriginationFee: Float!
    $creatorOriginationFee: Float!
    $kaleCpmFee: Float!
    $creatorCpmFee: Float!
  ) {
    createBrandPaymentStructure(
      brandId: $brandId
      productType: $productType
      minQualityScore: $minQualityScore
      kaleOriginationFee: $kaleOriginationFee
      creatorOriginationFee: $creatorOriginationFee
      kaleCpmFee: $kaleCpmFee
      creatorCpmFee: $creatorCpmFee
    ) {
      id
      brand {
        id
      }
      minQualityScore
      productType
      kaleOriginationFee
      creatorOriginationFee
      kaleCpmFee
      creatorCpmFee
    }
  }
`);

export const DELETE_BRAND_PAYMENT_STRUCTURE = gql(`
  mutation DeleteBrandPaymentStructure($brandPaymentId: ID!) {
    deleteBrandPaymentStructure(brandPaymentId: $brandPaymentId)
  }
`);

export const COMPLETE_BONSAI_TASK = gql(`
  mutation CompleteBonsaiTask(
    $queueName: String!
    $taskId: Int!
    $outcome: JSON!
  ) {
    completeBonsaiTask(
      queueName: $queueName
      taskId: $taskId
      outcome: $outcome
    ) {
      taskId
    }
  }
`);

export const CREATE_BRAND_PRODUCT = gql(`
  mutation CreateBrandProduct($brandId: ID!, $externalUrl: String!) {
    createBrandProduct(brandId: $brandId, externalUrl: $externalUrl) {
      id
      externalUrl
    }
  }
`);

export const CREATE_CHALLENGE_CATEGORY = gql(`
  mutation CreateChallengeCategory(
    $name: String!
    $template: String!
    $brandCategoryIds: [ID!]
  ) {
    createChallengeCategory(
      name: $name
      template: $template
      brandCategoryIds: $brandCategoryIds
    ) {
      id
      name
      template
      brandCategories {
        id
        name
      }
    }
  }
`);

export const UPDATE_CHALLENGE_CATEGORY = gql(`
  mutation UpdateChallengeCategory(
    $categoryId: ID!
    $name: String
    $template: String
    $brandCategoryIds: [ID!]
  ) {
    updateChallengeCategory(
      categoryId: $categoryId
      name: $name
      template: $template
      brandCategoryIds: $brandCategoryIds
    ) {
      id
      name
      template
      brandCategories {
        id
        name
      }
    }
  }
`);

export const UPDATE_ADMIN = gql(`
  mutation UpdateAdmin(
    $adminId: ID!
    $name: String!
    $role: String!
    $permissions: [ID!]!
  ) {
    updateAdmin(
      adminId: $adminId
      name: $name
      role: $role
      permissions: $permissions
    ) {
      id
      name
      email
      role
    }
  }
`);

export const UPDATE_ADMIN_PERMISSION = gql(`
  mutation UpdateAdminPermission(
    $adminPermissionId: ID!
    $description: String!
    $roles: [String!]!
    $updateAdminToPermissionMappings: Boolean!
  ) {
    updateAdminPermission(
      adminPermissionId: $adminPermissionId
      description: $description
      roles: $roles
      updateAdminToPermissionMappings: $updateAdminToPermissionMappings
    ) {
      id
      groupName
      permissionName
      description
      roles
    }
  }
`);

export const CREATE_ADMIN_PERMISSION = gql(`
  mutation CreateAdminPermission(
    $groupName: String!
    $permissionName: String!
    $description: String!
    $roles: [String!]!
    $updateAdminToPermissionMappings: Boolean!
  ) {
    createAdminPermission(
      groupName: $groupName
      permissionName: $permissionName
      description: $description
      roles: $roles
      updateAdminToPermissionMappings: $updateAdminToPermissionMappings
    ) {
      id
      groupName
      permissionName
      description
      roles
    }
  }
`);

export const SET_BRAND_SAGE_PACING = gql(`
  mutation SetBrandSagePacing($id: ID!, $enablePacing: Boolean!) {
    setBrandSagePacing(brandId: $id, enablePacing: $enablePacing) {
      ...SageSettingsFragment
    }
  }
`);

export const SET_BRAND_SAGE_CUSTOM_SCHEDULE = gql(`
  mutation SetBrandSageCustomSchedule($id: ID!, $schedule: CreateCustomSageSchedule) {
    setBrandCustomSageSchedule(brandId: $id, schedule: $schedule) {
      ...SageSettingsFragment
    }
  }
`);

export const ADD_CHALLENGE_BUDGET = gql(`
  mutation AddSageChallengeBudget($id: ID!, $challengeId: Int!, $budget: Int!) {
    addSageChallengeBudget(brandId: $id, challengeId: $challengeId, budget: $budget) {
      ...SageSettingsFragment
    }
  }
`);

export const UPDATE_CHALLENGE_BUDGET = gql(`
  mutation UpdateSageChallengeBudget($brandId: ID!, $challengeId: Int!, $budget: Int!) {
    updateSageChallengeBudget(brandId: $brandId, challengeId: $challengeId, budget: $budget) {
      ...SageSettingsFragment
    }
  }
`);

export const REMOVE_CHALLENGE_BUDGET = gql(`
  mutation RemoveSageChallengeBudget($brandId: ID!, $challengeId: Int!) {
    removeSageChallengeBudget(brandId: $brandId, challengeId: $challengeId) {
      ...SageSettingsFragment
    }
  }
`);

export const CREATE_BRAND = gql(`
   mutation CreateBrand($name: String!, $socialHandle: String!, $tiktokSocialHandle: String!, $referralCode: String!, $brandCategoryIds: [ID!]!, $salesRepAdminId: String, $accountManagerAdminId: String) {
    createBrand(name: $name, socialHandle: $socialHandle, tiktokSocialHandle: $tiktokSocialHandle, referralCode: $referralCode, brandCategoryIds: $brandCategoryIds, salesRepAdminId: $salesRepAdminId, accountManagerAdminId: $accountManagerAdminId) {
      id
      name
      socialHandle
      tiktokSocialHandle
      referralCode
      brandCategories {
        id
        name
      }
      salesRep {
        id
        name
      }
      accountManager {
        id
        name
      }
    }
  }
`);

export const UPDATE_BRAND_DETAILS = gql(`
  mutation UpdateBrandDetails($brandId: ID!, $name: String!, $socialHandle: String!, $tiktokSocialHandle: String!, $referralCode: String!, $brandCategoryIds: [ID!]!, $salesRepAdminId: String, $accountManagerAdminId: String) {
    updateBrandDetails(brandId: $brandId, name: $name, socialHandle: $socialHandle, tiktokSocialHandle: $tiktokSocialHandle, referralCode: $referralCode, brandCategoryIds: $brandCategoryIds, salesRepAdminId: $salesRepAdminId, accountManagerAdminId: $accountManagerAdminId) {
      id
      name
      socialHandle
      tiktokSocialHandle
      referralCode
      brandCategories {
        id
        name
      }
      salesRep {
        id
        name
      }
      accountManager {
        id
        name
      }
      status
    }
  }
`);

export const UPDATE_BRAND_ASSETS = gql(`
  mutation UpdateBrandAssets($brandId: ID!, $logoBucketPath: String, $coverBucketPath: String) {
    updateBrandAssets(brandId: $brandId, logoBucketPath: $logoBucketPath, coverBucketPath: $coverBucketPath) {
      id
      logoUrl
      coverUrl
      status
    }
  }
`);

export const UPDATE_BRAND_SETTINGS = gql(`
  mutation UpdateBrandSettings(
    $brandId: ID!,
    $requiresHighQualityScore: Boolean!,
    $isOffersActive: Boolean!,
    $isChallengeReviewRequired: Boolean!,
    $shouldRequireCommercialSound: Boolean!,
    $isSelfService: Boolean!
  ) {
    updateBrandSettings(
      brandId: $brandId,
      requiresHighQualityScore: $requiresHighQualityScore,
      isOffersActive: $isOffersActive,
      isChallengeReviewRequired: $isChallengeReviewRequired,
      shouldRequireCommercialSound: $shouldRequireCommercialSound
      isSelfService: $isSelfService
    ) {
      id
      requiresHighQualityScore
      isOffersActive
      isChallengeReviewRequired
      shouldRequireCommercialSound
      status
      isSelfService
    }
  }
`);

export const UPDATE_BRAND_ADMINS_AND_ROLES = gql(`
  mutation UpdateBrandAdminsAndRoles(
    $brandId: ID!,
    $admins: [AdminInput!]!
  ) {
    updateBrandAdminsAndRoles(brandId: $brandId, admins: $admins) {
      id
      admins {
        id
        name
        email
        role
        administeredBrands {
          brand {
            id
          }
          role
        }
      }
      status
    }
  }
`);

export const UPDATE_BRAND_CONTENT_INFO = gql(`
  mutation UpdateBrandContentInfo(
    $brandId: ID!,
    $requiredHashtags: [String!],
    $kaleHashtags: [String!],
    $requiredMentions: [ID!],
    $deniedBrands: [ID!]
  ) {
    updateBrandContentInfo(
      brandId: $brandId,
      requiredHashtags: $requiredHashtags,
      kaleHashtags: $kaleHashtags,
      requiredMentions: $requiredMentions,
      deniedBrands: $deniedBrands
    ) {
      id
      hashtagRequirements
      kaleHashtagRequirements
      mentionRequirements {
        id
        name
      }
      deniedBrands {
        id
        name
      }
      status
    }
  }
`);

export const UPDATE_BRAND_INSTAGRAM_INFO = gql(`
  mutation UpdateBrandInstagramInfo(
    $brandId: ID!
    $isIgStoryReplyEnabled: Boolean!
    $maxFollowerCount: Float
    $minFollowerCount: Float
    $title: String
    $subtitle: String
    $imageBucketPath: String
  ) {
    updateBrandInstagramInfo(
      brandId: $brandId
      isIgStoryReplyEnabled: $isIgStoryReplyEnabled
      maxFollowerCount: $maxFollowerCount
      minFollowerCount: $minFollowerCount
      title: $title
      subtitle: $subtitle
      imageBucketPath: $imageBucketPath
    ) {
      id
      isIgStoryReplyEnabled
      instagramAutomatedMessageSettings {
        title
        subtitle
        maxFollowerCount
        minFollowerCount
        imageUrl
      }
    }
  }
`);

export const UPDATE_BRAND_AVAILABILITY_MUTATION = gql(`
  mutation UpdateBrandAvailability($brandId: ID!, $countryCodeAllowList: [String!]!) {
    updateBrandAvailability(brandId: $brandId, countryCodeAllowList: $countryCodeAllowList) {
      id
      countryCodeAllowList
    }
  }
`);

export const TURN_BRAND_ON_AS_KALE_BRAND = gql(`
  mutation TurnBrandOnAsKaleBrand($brandId: ID!) {
    turnBrandOnAsKaleBrand(brandId: $brandId) {
      id
      isKaleBrand
      status
    }
  }
`);

export const UPDATE_BRAND_CREATOR_PAYMENTS_INFO = gql(`
  mutation UpdateBrandCreatorPaymentsInfo(
    $brandId: ID!,
    $maxCreatorPayout: Float,
    $monthlyMaxCreatorPayout: Float,
    $monthlyMaxCreatorChallenges: Int,
    $isKaleOffsetEnabled: Boolean!,
  ) {
    updateBrandCreatorPaymentsInfo(
      brandId: $brandId,
      maxCreatorPayout: $maxCreatorPayout,
      monthlyMaxCreatorPayout: $monthlyMaxCreatorPayout,
      monthlyMaxCreatorChallenges: $monthlyMaxCreatorChallenges,
      isKaleOffsetEnabled: $isKaleOffsetEnabled,
    ) {
      id
      maxCreatorPayout
      monthlyMaxCreatorPayout
      monthlyMaxCreatorChallenges
      status
      isKaleOffsetEnabled
    }
  }
`);

export const UPDATE_BRAND_INVOICING_INFO = gql(`
  mutation UpdateBrandInvoicingInfo(
    $brandId: ID!,
    $recurringChargesEnabled: Boolean!,
    $autoRechargeEnabled: Boolean!,
    $autoChargeDefaultPaymentMethod: Boolean!,
    $enableWireTransferInvoicePayment: Boolean!,
    $platformFee: Float!,
    $contentBudgetMax: Float!,
    $paymentPeriodDays: Int!,
    $invoiceEmailAddress: String!
  ) {
    updateBrandInvoicingInfo(
      brandId: $brandId,
      recurringChargesEnabled: $recurringChargesEnabled,
      autoRechargeEnabled: $autoRechargeEnabled,
      autoChargeDefaultPaymentMethod: $autoChargeDefaultPaymentMethod,
      enableWireTransferInvoicePayment: $enableWireTransferInvoicePayment,
      platformFee: $platformFee,
      contentBudgetMax: $contentBudgetMax,
      paymentPeriodDays: $paymentPeriodDays,
      invoiceEmailAddress: $invoiceEmailAddress
    ) {
      id
      recurringChargesEnabled
      autoRechargeEnabled
      autoChargeDefaultPaymentMethod
      enableWireTransferInvoicePayment
      invoiceEmailAddress
      platformFee
      contentBudgetMax
      status
      paymentPeriodDays
    }
  }
`);

export const TURN_BRAND_OFF_AS_KALE_BRAND = gql(`
  mutation TurnBrandOffAsKaleBrand($brandId: ID!) {
    turnBrandOffAsKaleBrand(brandId: $brandId) {
      id
      isKaleBrand
      status
    }
  }
`);
