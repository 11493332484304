import { useMutation } from "@apollo/client";
import {
  CheckCircleIcon,
  MinusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import {
  CreatorProfileData,
  ExternalUser,
  Spinner,
  numberWithCommas,
  prettyDecimal,
} from "@kalecard/common";
import { NavLink } from "react-router-dom";
import {
  REFRESH_USER_SOCIAL_DATA,
  REMOVE_EXTERNAL_USER,
  RESET_CREATOR_BIRTHDATE,
} from "../../graphql/mutations";
import { useState } from "react";
import CreatorAccountStatusUpdate from "./CreatorAccountStatusUpdate";
import { CREATOR_PROFILE_DATA } from "../../graphql/queries";
import CreatorAccountState from "./CreatorAccountState";

export enum CreatorTab {
  TRANSACTIONS,
  DEALS,
  MEDIA,
}

interface CreatorHeaderInterface {
  creatorProfileData: CreatorProfileData;
}

export default function CreatorHeader({
  creatorProfileData,
}: CreatorHeaderInterface) {
  const [externalUsers, setExternalUsers] = useState<ExternalUser[]>(
    creatorProfileData.creator?.externalUsers
  );
  const [refreshUserSocialData] = useMutation(REFRESH_USER_SOCIAL_DATA);
  const [resetCreatorBirthdate] = useMutation(RESET_CREATOR_BIRTHDATE);

  const [requiresApproval, setRequiresApproval] = useState(
    creatorProfileData?.creator?.creatorSettings?.requiresApproval ?? false
  );
  const tabs = [
    { name: "Deals", href: `deals`, current: false },
    { name: "Posts", href: `post`, current: false },
    { name: "Messages", href: `messages`, current: true },
    { name: "Transactions", href: `transactions`, current: false },
    { name: "Actions", href: `actions`, current: false },
  ];

  const refreshSocialData = async () => {
    console.log("Refreshing data");
    await refreshUserSocialData({
      variables: {
        userId: creatorProfileData.creator.id,
      },
    });
  };

  const handleResetCreatorBirthdate = async () => {
    await resetCreatorBirthdate({
      variables: {
        creatorId: creatorProfileData.creator.id,
      },
      refetchQueries: [CREATOR_PROFILE_DATA],
    });
  };

  const birthdate = creatorProfileData.creator.birthdate;
  let isUnderage = false;
  let date13YearsAgo = new Date();
  date13YearsAgo.setFullYear(date13YearsAgo.getFullYear() - 13);
  if (birthdate && new Date(birthdate) > date13YearsAgo) {
    isUnderage = true;
  }

  return (
    <div className="border-b border-gray-200 pb-5 sm:pb-0">
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        {creatorProfileData && (
          <div className="flex justify-between">
            <div className="flex items-center space-x-4">
              <div className="flex flex-col">
                {externalUsers?.map((externalUser) => {
                  return (
                    <ExternalUserItem
                      key={externalUser.id}
                      externalUser={externalUser}
                      numExternalUsers={externalUsers.length}
                      setExternalUsers={setExternalUsers}
                    />
                  );
                })}
              </div>
              <div className="flex flex-col text-left text-base">
                <div>{creatorProfileData.creator.phoneNumber}</div>
                <div>{creatorProfileData.creator.name}</div>
                <div>{creatorProfileData.creator.email}</div>
                <div className="flex">
                  Plaid{" "}
                  {creatorProfileData.creator.creatorSettings?.isPlaidActive ? (
                    <CheckCircleIcon
                      className="-mr-0.5 ml-2 h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <MinusCircleIcon
                      className="-mr-0.5 ml-2 h-6 w-6 text-red-500"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col text-left text-base">
                <div>
                  Referral Code:{" "}
                  <span className="font-bold">
                    {creatorProfileData.creator.referralCode}
                  </span>
                </div>
                <div>
                  Completed Challenges:{" "}
                  <span className="font-bold">
                    {numberWithCommas(creatorProfileData.dealsCompleted)}
                  </span>
                </div>
                <div>
                  Total Earnings:{" "}
                  <span className="font-bold">
                    ${prettyDecimal(creatorProfileData.totalEarnings)}
                  </span>
                </div>
                <div>
                  Current Balance:{" "}
                  <span className="font-bold">
                    ${prettyDecimal(creatorProfileData?.creator?.balance)}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              {requiresApproval ? (
                <CreatorAccountStatusUpdate
                  creator={creatorProfileData.creator}
                  requiresApproval={(val) => setRequiresApproval(val)}
                />
              ) : (
                <CreatorAccountState state={creatorProfileData.creator.accountState} />
              )}
            </div>
            {(creatorProfileData.creator.accountState === "SCHEDULED_FOR_BLOCKED_UNDERAGE") && (
              <div className="flex items-center space-x-3">
                <button
                  onClick={handleResetCreatorBirthdate}
                  className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Reset Birthdate
                </button>
              </div>
            )}
            <div className="flex items-center space-x-3">
              <button
                onClick={() => refreshSocialData()}
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
              >
                Refresh
              </button>
            </div>
          </div>
        )}
      </h3>
      <div className="mt-3 sm:mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                activeClassName={
                  "border-indigo-500 text-indigo-600 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                }
                className={
                  "whitespace-nowrap border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-indigo-300 hover:text-indigo-700"
                }
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

function ExternalUserItem({
  externalUser,
  numExternalUsers,
  setExternalUsers,
}: {
  externalUser: ExternalUser;
  numExternalUsers: number;
  setExternalUsers: React.Dispatch<React.SetStateAction<ExternalUser[]>>;
}) {
  const [removeExternalUserMutation, { loading, error }] =
    useMutation(REMOVE_EXTERNAL_USER);

  const removeExternalUser = async () => {
    await removeExternalUserMutation({
      variables: {
        externalUserId: externalUser.id,
      },
    });
    setExternalUsers((externalUsers) =>
      externalUsers.filter((user) => user.id !== externalUser.id)
    );
  };

  return (
    <div className="flex flex-col">
      <div key={externalUser.id} className="flex items-center">
        <a
          href={
            externalUser.externalPlatform === "TIKTOK"
              ? `https://tiktok.com/@${externalUser.username}`
              : `https://instagram.com/${externalUser.username}`
          }
          target="_blank"
          className="block cursor-pointer rounded p-1 hover:bg-gray-100 hover:text-green-800"
        >
          <div className="flex items-center space-x-1">
            <img
              className={`${numExternalUsers > 1 ? "h-5 w-5" : "h-12 w-12"
                } rounded-full`}
              src={externalUser.pictureUrl}
              alt=""
            />

            <div className="flex items-center text-base text-green-600">
              {externalUser.username}
            </div>
          </div>
        </a>
        <div className="ml-1 flex items-center text-base ">
          | {numberWithCommas(externalUser.followers)} followers |{" "}
          <span className="ml-1">
            {externalUser.externalPlatform === "TIKTOK" ? (
              <svg
                fill="currentColor"
                viewBox="0 0 30 30"
                width="30px"
                height="30px"
              >
                <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.104,4,24,4z M22.689,13.474 c-0.13,0.012-0.261,0.02-0.393,0.02c-1.495,0-2.809-0.768-3.574-1.931c0,3.049,0,6.519,0,6.577c0,2.685-2.177,4.861-4.861,4.861 C11.177,23,9,20.823,9,18.139c0-2.685,2.177-4.861,4.861-4.861c0.102,0,0.201,0.009,0.3,0.015v2.396c-0.1-0.012-0.197-0.03-0.3-0.03 c-1.37,0-2.481,1.111-2.481,2.481s1.11,2.481,2.481,2.481c1.371,0,2.581-1.08,2.581-2.45c0-0.055,0.024-11.17,0.024-11.17h2.289 c0.215,2.047,1.868,3.663,3.934,3.811V13.474z" />
              </svg>
            ) : (
              <svg
                fill="currentColor"
                viewBox="0 0 30 30"
                width="30px"
                height="30px"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </span>{" "}
          <span className="ml-1 font-bold">
            {externalUser.isPrivate && "| Private"}
          </span>
        </div>
        {externalUser.status === "CONNECTED" ? (
          <CheckCircleIcon
            className="h-5 w-5 text-green-500"
            aria-hidden="true"
          />
        ) : (
          <MinusCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        )}
        <div className="flex space-x-2">
          <button
            disabled={loading}
            className="disabled:opacity-60"
            onClick={() => {
              removeExternalUser();
            }}
          >
            <XCircleIcon
              className="ml-2 h-5 w-5 rounded-lg shadow-lg"
              aria-hidden="true"
            />
          </button>
          {loading && <Spinner size="h-4 w-4" />}
        </div>
      </div>
      {error && <div className="text-xs text-red-500">{error.message}</div>}
    </div>
  );
}
