import { Challenge, ChallengeComponent, Modal } from "@kalecard/common";
import { useContext, useState } from "react";
import { ChallengesContext } from "../../providers/ChallengesProvider";
import { ChallengesActionType } from "../../reducers/ChallengesReducer";
import NewChallengeForm from "../forms/NewChallengeForm";
import ChallengesTable from "./ChallengesTable";
import { classNames } from "../../utils/style";

export default function AdminChallenges() {
  const [isNewChallengeModalOpen, setIsNewChallengeModalOpen] = useState(false);
  const [newChallenge, setNewChallenge] = useState(null);

  const {
    dispatch: challengesDispatch,
    state: {
      pendingChallengesCount,
      feedbackCount,
      challengeState,
      additionalFilters,
      source,
    },
  } = useContext(ChallengesContext);

  const handleModalSubmit = (newChallenge: Challenge) => {
    console.log(newChallenge);
    setIsNewChallengeModalOpen(false);
    challengesDispatch({
      type: ChallengesActionType.NEW_CHALLENGE,
      payload: newChallenge,
    });
  };

  return (
    <div className="mt-4 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Challenges</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the challenges
          </p>
        </div>

        <div className="mt-4 flex divide-x divide-x-2 sm:ml-16 sm:mt-0">
          <div
            className={classNames(
              additionalFilters?.containingFeedback
                ? "font-bold text-indigo-800"
                : "font-medium text-indigo-700",
              "flex items-center space-x-2 pr-2 text-sm hover:cursor-pointer"
            )}
            onClick={() => {
              challengesDispatch({
                type: ChallengesActionType.ADD_FILTER_FOR_FEEDBACK,
              });
            }}
          >
            <p>New Feedback</p>
            {feedbackCount > 0 && (
              <div className="rounded-xl bg-indigo-500 px-2 py-0.5 text-xs text-white">
                {feedbackCount < 9 ? feedbackCount : "9+"}
              </div>
            )}
          </div>
          <div
            className={classNames(
              challengeState?.id === "PENDING_REVIEW"
                ? "font-bold text-indigo-800"
                : "font-medium text-indigo-700",
              "flex items-center space-x-2 px-2 text-sm hover:cursor-pointer"
            )}
            onClick={() => {
              challengesDispatch({
                type: ChallengesActionType.ADD_FILTER_FOR_PENDING_REVIEW,
              });
            }}
          >
            <p>Challenge Suggestions</p>
            {pendingChallengesCount > 0 && (
              <div className="rounded-xl bg-indigo-500 px-2 py-0.5 text-xs text-white">
                {pendingChallengesCount < 9 ? pendingChallengesCount : "9+"}
              </div>
            )}
          </div>
          <div className="pl-2">
            <button
              type="button"
              onClick={() => {
                setIsNewChallengeModalOpen(true);
              }}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              New challenge
            </button>
          </div>
        </div>
      </div>
      <ChallengesTable newChallengesOnly={true} />
      <ChallengesTable newChallengesOnly={false} />
      <Modal
        isModalOpen={isNewChallengeModalOpen}
        onClose={() => setIsNewChallengeModalOpen(false)}
      >
        <div className="flex w-full divide-x">
          <div className="mr-3 w-2/5 flex flex-col space-y-2 pt-2">
            <p className="font-medium text-sm">Challenge Preview:</p>
            <ChallengeComponent challenge={newChallenge} />
          </div>
          <div className="pl-4 m-1 w-2/3">
            <NewChallengeForm
              setNewChallenge={setNewChallenge}
              onSubmit={handleModalSubmit}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
