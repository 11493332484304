import { Brand, BrandCategory, Connection, Edge } from "@kalecard/common";
import { DocumentNode } from "graphql";
import apolloClient from "../config/apolloClient";
import { BRANDS, BRAND_CATEGORIES, KALE_BRANDS } from "../graphql/queries";

interface BrandsData {
  brands: Connection<Brand>;
}

interface BrandCategoriesData {
  brandCategories: Connection<BrandCategory>;
}

function getBrands(
  first: number,
  after?: number,
  onlyRelevantBrands?: boolean,
  isKaleBrand?: boolean,
  sortingType?: string
) {
  return apolloClient.query<BrandsData>({
    query: BRANDS,
    variables: {
      first: first,
      after: after,
      onlyRelevantBrands: onlyRelevantBrands,
      isKaleBrand: isKaleBrand,
      sortingType: sortingType
    },
  });
}

export function getKaleBrands(
  first: number,
  after?: number,
  autoRechargeEnabled?: boolean,
  challengesPaused?: boolean,
  sortingType?: string,
  pacingWithSage?: boolean
) {
  return apolloClient.query<BrandsData>({
    query: KALE_BRANDS,
    variables: {
      first: first,
      after: after,
      autoRechargeEnabled: autoRechargeEnabled,
      challengesPaused: challengesPaused,
      sortingType: sortingType,
      isKaleBrand: true,
      pacingWithSage: pacingWithSage
    },
  });
}

function getBrandCategories(first: number, after?: number) {
  return apolloClient.query<BrandCategoriesData>({
    query: BRAND_CATEGORIES,
    variables: {
      first: first,
      after: after,
    },
  });
}

export async function getAllBrands(
  query: DocumentNode,
  onlyRelevantBrands?: boolean,
  isKaleBrand?: boolean,
  sortingType?: string
): Promise<Brand[]> {
  const first = 100;
  let after = 0;
  let hasNextPage = true;
  let brands: Edge<Brand>[] = [];

  while (hasNextPage) {
    const result = await getBrands(
      first,
      after,
      onlyRelevantBrands,
      isKaleBrand,
      sortingType
    );
    after = after + result.data.brands.edges.length;
    hasNextPage = result.data.brands.pageInfo.hasNextPage;

    const newBrands = [...brands, ...result.data.brands.edges];
    brands = newBrands;
  }

  return brands.map((edge) => edge.node);
}

export async function getAllBrandCategories(): Promise<BrandCategory[]> {
  const first = 100;
  let after = 0;
  let hasNextPage = true;
  let brands: Edge<BrandCategory>[] = [];

  while (hasNextPage) {
    const result = await getBrandCategories(first, after);
    after = after + result.data.brandCategories.edges.length;
    hasNextPage = result.data.brandCategories.pageInfo.hasNextPage;

    const newBrands = [...brands, ...result.data.brandCategories.edges];
    brands = newBrands;
  }

  return brands.map((edge) => edge.node);
}
