import { CommonToolProps } from "../BonsaiTypes";
import { BonsaiVideoPanel } from "@kalecard/common";
import { useQuery } from "@apollo/client";
import { GET_DOWNLOAD_SIGNED_URL } from "../../../graphql/queries";
import {
  GetDownloadSignedUrlQuery,
  GetDownloadSignedUrlQueryVariables,
  SignedUrlType,
} from "../../../__generated__/graphql";

interface ExperimentalPostScoringInput {
  postId: string;
}

enum ExperimentalPostScoringOutputType {
  Liked = "liked",
  Disliked = "disliked",
}

interface ExperimentalPostScoringOutput {
  result: ExperimentalPostScoringOutputType;
}

interface ExperimentalPostScoringProps
  extends CommonToolProps<
    ExperimentalPostScoringInput,
    ExperimentalPostScoringOutput
  > {}

export default function ExperimentalPostScoring(
  props: ExperimentalPostScoringProps
) {
  const { data, loading } = useQuery<
    GetDownloadSignedUrlQuery,
    GetDownloadSignedUrlQueryVariables
  >(GET_DOWNLOAD_SIGNED_URL, {
    variables: {
      mimeType: "video",
      id: props.input.postId,
      type: SignedUrlType.Post,
    },
  });

  const handleClick = (result: ExperimentalPostScoringOutputType) => {
    props.onComplete({ result });
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="mx-auto flex w-96 flex-col items-center">
      <BonsaiVideoPanel
        source={data.getDownloadSignedUrl}
        links={[]}
        autoPlay={false}
      />
      <div className="mt-4 flex space-x-4">
        <button
          className={`flex min-w-24 flex-1 items-center justify-center rounded-md border border-red-500 p-2 text-red-500 hover:bg-red-400 hover:text-white`}
          onClick={() =>
            handleClick(ExperimentalPostScoringOutputType.Disliked)
          }
        >
          Dislike
        </button>
        <button
          className={`flex min-w-24 flex-1 items-center justify-center rounded-md border border-green-500 p-2 text-green-500 hover:bg-green-400 hover:text-white`}
          onClick={() => handleClick(ExperimentalPostScoringOutputType.Liked)}
        >
          Like
        </button>
      </div>
    </div>
  );
}
