import {
  ArrowPathIcon,
  BuildingStorefrontIcon,
} from "@heroicons/react/24/solid";
import { Offer, timeDifferenceForDate } from "@kalecard/common";
import { useContext, useState } from "react";
import { OffersContext } from "../../providers/OffersProviders";
import { OffersActionType } from "../../reducers/OffersReducer";
import { useMutation } from "@apollo/client";
import { UPDATE_OFFER } from "../../graphql/mutations";

export default function OffersRow({ offer }: { offer: Offer }) {
  const [toggling, setToggling] = useState(false);

  const { dispatch: offersDispatch } = useContext(OffersContext);
  const [updateOffer] = useMutation(UPDATE_OFFER);

  const renderOfferState = (state: string) => {
    switch (state) {
      case "ACTIVE":
        return (
          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
            Active
          </span>
        );
      case "INACTIVE":
        return (
          <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
            Inactive
          </span>
        );
      default:
        return null;
    }
  };

  const toggle = async () => {
    setToggling(true);
    try {
      const result = await updateOffer({
        variables: {
          offerId: offer.id,
          state: offer.state === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        },
      });
      if (result.data.updateOffer) {
        offersDispatch({
          type: OffersActionType.UPDATE_OFFER,
          payload: result.data.updateOffer,
        });
      }
    } catch (err) {
      console.error(err);
    }

    setToggling(false);
  };

  return (
    <>
      <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm">
          <div className="flex items-center">
            <div className="h-10 w-10 flex-shrink-0">
              {offer?.brand?.logoUrl ? (
                <img
                  className="h-10 w-10 rounded-full"
                  src={offer?.brand?.logoUrl}
                  alt=""
                />
              ) : (
                <BuildingStorefrontIcon className="h-12 w-12 rounded-full border p-1" />
              )}
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900">
                {offer?.brand?.name}
              </div>
              <div className="text-xs text-gray-500">
                <p>Created {timeDifferenceForDate(Number(offer.createdAt))}</p>
                <p>ID: {offer.id}</p>
              </div>
            </div>
          </div>
        </td>
        <td className="px-4 py-4">
          <div className="flex whitespace-nowrap text-center text-sm text-gray-900">
            Creators:<p className="font-medium pl-2">{offer.cashbackPercentage * 100} %</p>
          </div>
          <div className="flex whitespace-nowrap text-center text-sm text-gray-900">
            Kale:<p className="font-medium pl-2">{offer.kaleFeePercentage ? offer.kaleFeePercentage * 100 : 0} %</p>
          </div>
        </td>
        <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-500">
          <div className="flex items-center">
            <div>{renderOfferState(offer.state)}</div>
            <div className="ml-2">
              <button
                onClick={toggle}
                className="rounded-full bg-kale-mint-500 p-1 opacity-70 hover:opacity-100"
              >
                <ArrowPathIcon
                  className={`h-4 w-4 text-kale-green-500 ${
                    toggling ? "animate-spin" : ""
                  }`}
                />
              </button>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}
