import { useQuery } from "@apollo/client";
import AdminOffers from "../components/offers/AdminOffers";
import { ADMIN_OFFERS, AdminOffersInterface } from "../graphql/queries";
import OffersProvider from "../providers/OffersProviders";
import { Spinner } from "flowbite-react";

export default function OffersPage() {
  const { data, loading } = useQuery<AdminOffersInterface>(ADMIN_OFFERS);

  return !loading ? (
    <OffersProvider
      offersState={{
        offers: data.adminOffers.edges.map((edge) => edge.node),
        hasNextPage: data.adminOffers.pageInfo.hasNextPage,
        after:
          data.adminOffers.edges[data.adminOffers.edges.length - 1]?.cursor,
      }}
    >
      <AdminOffers />
    </OffersProvider>
  ) : (
    <div className="flex justify-center pt-10">
      <Spinner size={"h-8 w-8"} />
    </div>
  );
}
