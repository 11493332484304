import {
  ArrowDownCircleIcon,
  ArrowRightIcon,
  ArrowUpCircleIcon,
  BuildingStorefrontIcon,
} from "@heroicons/react/24/solid";
import { ChallengeLog } from "@kalecard/common";
import { ChallengeState } from "./ChallengeState";
import KaleLogo from "../../kale-logo.svg";
import { ChallengeDifficulty } from "./SharedChallengeComponents";

enum ActorType {
  KALE_ADMIN,
  BRAND_ADMIN,
  AUTOMATION,
}

const getActorType = (challengeLog: ChallengeLog) => {
  return challengeLog.actorType === "KALE_ADMIN"
    ? ActorType.KALE_ADMIN
    : challengeLog.actorType === "BRAND_USER"
    ? ActorType.BRAND_ADMIN
    : ActorType.AUTOMATION;
};

const getChallengeDate = (date?: string) => {
  return date
    ? new Date(Number(date)).toLocaleDateString("en-us", {
        hourCycle: "h12",
        hour: "2-digit",
        minute: "2-digit",
      })
    : "?";
};

function ChallengeLogHeader({
  challengeLog,
  nextChallengeLog,
  isCreationComponent,
  actorType,
}: {
  challengeLog: ChallengeLog;
  nextChallengeLog?: ChallengeLog;
  isCreationComponent: boolean;
  actorType: ActorType;
}) {
  const action = () => {
    switch (actorType) {
      case ActorType.KALE_ADMIN:
        if (isCreationComponent) {
          return "created on";
        } else if (challengeLog.state !== nextChallengeLog?.state) {
          if (challengeLog.state == "INACTIVE") {
            return nextChallengeLog?.state === "PENDING_REVIEW"
              ? "approved on"
              : "deactivated on";
          } else if (challengeLog.state === "ACTIVE") {
            return "activated on";
          } else if (challengeLog.state === "REJECTED") {
            return "rejected on";
          } else {
            return "updated on";
          }
        } else {
          return "updated on";
        }
      case ActorType.BRAND_ADMIN:
        if (isCreationComponent) {
          return "suggested on";
        } else if (challengeLog.state !== nextChallengeLog?.state) {
          return "approved on";
        } else {
          return "updated on";
        }
      case ActorType.AUTOMATION:
        if (challengeLog.state !== nextChallengeLog?.state) {
          return challengeLog.state === "INACTIVE"
            ? "deactivated on"
            : "activated on";
        } else {
          return "updated on";
        }
    }
  };

  return (
    <div className="flex space-x-1.5">
      <p className="text-sm font-medium text-gray-900">
        {challengeLog.actorName}
      </p>
      <p className="text-sm italic text-gray-500">{action()}</p>
      <div>
        <p className="text-sm text-gray-900">
          {new Date(Number(challengeLog.createdAt)).toLocaleDateString(
            "en-us",
            { hourCycle: "h12", hour: "2-digit", minute: "2-digit" }
          )}
        </p>
      </div>
    </div>
  );
}

function ChallengeCreationBody({
  challengeLog,
}: {
  challengeLog: ChallengeLog;
}) {
  return (
    <div className="text-sm text-gray-800">
      <div className="my-4 text-sm font-medium text-kale-green-500">
        <p className="text-kale-green-1000">{challengeLog.title}</p>
        <p>{challengeLog.description}</p>
      </div>
      {(challengeLog.startDate || challengeLog.endDate) && (
        <div className="flex space-x-1 text-xs text-gray-900">
          <p className="font-semibold">Active Period:</p>
          <p className="font-medium text-kale-green-500">
            {getChallengeDate(challengeLog.startDate)}
            {" --> "}
            {getChallengeDate(challengeLog.endDate)}
          </p>
        </div>
      )}
      {challengeLog.exampleUrl && (
        <div className="flex space-x-1 text-xs text-gray-900">
          <p className="font-semibold">Example URL:</p>
          <p className="font-medium text-kale-green-500">
            {challengeLog.exampleUrl}
          </p>
        </div>
      )}
      {challengeLog.difficulty && (
        <div className="flex items-center space-x-1 text-xs text-gray-900">
          <p className="font-semibold">Difficulty:</p>
          <ChallengeDifficulty difficulty={challengeLog.difficulty} />
        </div>
      )}
    </div>
  );
}

function ChallengeUpdateBody({
  challengeLog,
  nextChallengeLog,
}: {
  challengeLog: ChallengeLog;
  nextChallengeLog?: ChallengeLog;
}) {
  return (
    <div className="space-y-2 pt-4">
      {challengeLog.state !== nextChallengeLog?.state && (
        <div className="flex flex-col text-sm">
          <p>State:</p>
          <div className="flex items-center space-x-2">
            <div className="text-gray-500 decoration-kale-green-500">
              <ChallengeState state={nextChallengeLog?.state} />
            </div>
            <ArrowRightIcon className="h-4 w-4" />
            <div className="font-medium text-kale-green-500">
              <ChallengeState state={challengeLog.state} />
            </div>
          </div>
        </div>
      )}

      {challengeLog.title !== nextChallengeLog?.title && (
         <div className="flex flex-col text-sm">
         <p>Title:</p>
         <div className="flex space-x-2">
           {nextChallengeLog.title && (
             <p className="flex text-gray-500 line-through decoration-kale-green-500">
               {nextChallengeLog?.title}
             </p>
           )}
           {challengeLog.title && (
             <p className="flex font-medium text-kale-green-500">
               {challengeLog.title}
             </p>
           )}
         </div>
       </div>
      )}

      {challengeLog.description !== nextChallengeLog?.description && (
        <div className="flex flex-col text-sm">
          <p>Description:</p>
          <div className="flex space-x-2">
            <p className="text-gray-500 line-through decoration-kale-green-500">
              {nextChallengeLog?.description}
            </p>
            <p className="font-medium text-kale-green-500">
              {challengeLog.description}
            </p>
          </div>
        </div>
      )}
      {challengeLog.exampleUrl !== nextChallengeLog?.exampleUrl && (
        <div className="flex flex-col text-sm">
          <p>Example URL:</p>
          <div className="flex space-x-2">
            {nextChallengeLog.exampleUrl && (
              <p className="flex text-gray-500 line-through decoration-kale-green-500">
                {nextChallengeLog?.exampleUrl}
              </p>
            )}
            {challengeLog.exampleUrl && (
              <p className="flex font-medium text-kale-green-500">
                {challengeLog.exampleUrl}
              </p>
            )}
          </div>
        </div>
      )}
      {(challengeLog.startDate !== nextChallengeLog?.startDate ||
        challengeLog.endDate !== nextChallengeLog?.endDate) && (
        <div className="flex flex-col text-sm">
          <p>Activation Period:</p>
          <div className="flex space-x-2">
            {(nextChallengeLog?.startDate || nextChallengeLog?.endDate) && (
              <div className="flex space-x-2 text-gray-500 line-through decoration-kale-green-500">
                <p>
                  {getChallengeDate(nextChallengeLog.startDate)}
                  {" --> "}
                  {getChallengeDate(nextChallengeLog.endDate)}
                </p>
              </div>
            )}
            {(challengeLog.startDate || challengeLog.endDate) && (
              <div className="flex space-x-2 font-medium text-kale-green-500">
                <p>
                  {getChallengeDate(challengeLog.startDate)}
                  {" --> "}
                  {getChallengeDate(challengeLog.endDate)}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {challengeLog.rank !== nextChallengeLog?.rank && (
        <div className="flex items-center space-x-2 text-sm">
          <p>Rank:</p>
          <div className="flex space-x-2">
            {nextChallengeLog?.rank > challengeLog.rank ||
            nextChallengeLog?.rank === null ? (
              <div className="text-green-500">
                <ArrowUpCircleIcon className="h-5 w-5" />
              </div>
            ) : (
              <div className="text-red-500">
                <ArrowDownCircleIcon className="h-5 w-5" />
              </div>
            )}
          </div>
        </div>
      )}
      {challengeLog.difficulty !== nextChallengeLog?.difficulty && (
        <div className="flex flex-col text-sm">
          <p>Difficulty:</p>
          <div className="flex items-center space-x-2">
            {nextChallengeLog?.difficulty && (
              <ChallengeDifficulty difficulty={nextChallengeLog?.difficulty} />
            )}
            {nextChallengeLog?.difficulty && challengeLog.difficulty && (
              <ArrowRightIcon className="h-4 w-4" />
            )}
            {challengeLog.difficulty && (
              <ChallengeDifficulty difficulty={challengeLog.difficulty} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function ChallengeLogLogo({
  brandLogoUrl,
  actorType,
}: {
  brandLogoUrl?: string;
  actorType: ActorType;
}) {
  return (
    <div className="h-10 w-10 flex-shrink-0">
      {actorType === ActorType.BRAND_ADMIN ? (
        brandLogoUrl ? (
          <img className="h-10 w-10 rounded-full" src={brandLogoUrl} alt="" />
        ) : (
          <BuildingStorefrontIcon className="h-10 w-10 rounded-full border p-1" />
        )
      ) : (
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-kale-mint-400">
          <img className="h-6 w-6" src={KaleLogo} alt="Kale" />
        </div>
      )}
    </div>
  );
}

export function ChallengeHistoryComponent({
  createdAt,
  challengeLog,
  nextChallengeLog,
  brandLogoUrl,
  willShowNextEntry,
}: {
  createdAt: string;
  challengeLog: ChallengeLog;
  nextChallengeLog?: ChallengeLog;
  brandLogoUrl?: string;
  willShowNextEntry: boolean;
}) {
  const isCreationComponent = createdAt === challengeLog.createdAt;
  const actorType = getActorType(challengeLog);

  const body = isCreationComponent ? (
    <ChallengeCreationBody challengeLog={challengeLog} />
  ) : (
    <ChallengeUpdateBody
      challengeLog={challengeLog}
      nextChallengeLog={nextChallengeLog}
    />
  );

  const logItem = (
    <li>
      <div>
        <div className="relative flex items-start space-x-3">
          <div className="flex flex-col items-center">
            <ChallengeLogLogo
              brandLogoUrl={brandLogoUrl}
              actorType={actorType}
            />
            {willShowNextEntry && (
              <span
                className="absolute left-5 top-10 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              ></span>
            )}
          </div>
          <div className="min-w-0 flex-1 rounded-md p-3 ring-1 ring-inset ring-gray-200">
            <ChallengeLogHeader
              challengeLog={challengeLog}
              isCreationComponent={isCreationComponent}
              nextChallengeLog={nextChallengeLog}
              actorType={actorType}
            />
            {body}
          </div>
        </div>
      </div>
    </li>
  );

  return <>{logItem}</>;
}
