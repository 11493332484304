import { Brand, PostRequirement, Spinner } from "@kalecard/common";
import {
  PostRequirementComponent,
  NewPostRequirement,
} from "././PostRequirementRow";

export interface PostRequirementsSaveData {
  successMessage?: string;
  errorMessage?: string;
  loadingUpdate: boolean;
}

export default function PostRequirementsTable({
  ownerName,
  brand,
  showRequiredColumn,
  postRequirements,
  handleNewRequirement,
  handleUpdateRequirement,
  handleToggleMapToBrand,
  showSave,
  handleSave,
  showTableHeader,
  postRequirementsSaveData,
  onRemove,
}: {
  ownerName: string;
  brand: Brand;
  showRequiredColumn: boolean;
  postRequirements: PostRequirement[];
  handleNewRequirement: (postRequirement: PostRequirement) => void;
  handleUpdateRequirement: (postRequirement: PostRequirement) => void;
  handleToggleMapToBrand?: (postRequirement: PostRequirement) => void;
  showSave: boolean;
  handleSave?: () => void;
  showTableHeader: boolean;
  postRequirementsSaveData?: PostRequirementsSaveData;
  onRemove?: (postRequirement: PostRequirement) => void;
}) {
  return (
    <div>
      <div className="flex justify-between">
        {showTableHeader && (
          <div className="flex flex-col p-2">
            <h3 className="text-lg font-medium text-gray-900">
              Post Requirements
            </h3>
            <p className="text-sm text-gray-900">
              Select which requirements to add for {ownerName}.
            </p>
          </div>
        )}
        <div className="flex items-center space-x-2">
          {postRequirementsSaveData?.loadingUpdate && (
            <Spinner size="h-5 w-5" />
          )}
          {postRequirementsSaveData?.errorMessage && (
            <p className="text-sm text-red-500">
              {postRequirementsSaveData?.errorMessage}
            </p>
          )}
          {postRequirementsSaveData?.successMessage && (
            <p className="text-sm text-green-500">
              {postRequirementsSaveData?.successMessage}
            </p>
          )}
          {showSave && (
            <div
              className="h-fit justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:cursor-pointer hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={handleSave}
            >
              Save
            </div>
          )}
        </div>
      </div>
      <div className="flex">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-2 pl-4 text-left text-sm font-semibold text-gray-900 "
              >
                Emoji
              </th>
              <th
                scope="col"
                className="py-2 pl-4 pr-4 text-left text-sm font-semibold text-gray-900"
              >
                Requirement
              </th>
              {showRequiredColumn && (
                <th
                  scope="col"
                  className="py-2 text-left text-sm font-semibold text-gray-900"
                >
                  Required for {ownerName}
                </th>
              )}
              <th scope="col" className="py-2"></th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {postRequirements.map((postRequirement, index) => (
              <PostRequirementComponent
                key={postRequirement.id}
                postRequirement={postRequirement}
                brand={brand}
                postRequirementIdx={index}
                showRequiredColumn={showRequiredColumn}
                handleUpdateRequirement={handleUpdateRequirement}
                handleToggleMapToBrand={handleToggleMapToBrand}
                onRemove={onRemove}
              />
            ))}
            <tr
              className={
                postRequirements.length % 2 === 0 ? undefined : "bg-gray-50"
              }
            >
              <NewPostRequirement
                brand={brand}
                handleNewRequirement={handleNewRequirement}
              />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
