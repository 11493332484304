import { Brand, Modal } from "@kalecard/common";
import BrandPaymentStructureTable from "./BrandPaymentStructureTable";
import { useContext, useState } from "react";
import { BrandPaymentStructuresContext } from "../../providers/BrandPaymentStructuresProvider";
import { NewBrandPaymentStructure } from "./BrandPaymentStructureComponent";

export default function BrandPaymentStructures({ brand }: { brand: Brand }) {
  const {
    state: { brandPaymentStructures, defaultPaymentStructures },
  } = useContext(BrandPaymentStructuresContext);
  const [isNewPaymentStructureModalOpen, setIsNewPaymentStructureModalOpen] =
    useState(false);

  return (
    <div className="space-y-4">
      <div
        className="h-fit w-fit justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:cursor-pointer hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={() => setIsNewPaymentStructureModalOpen(true)}
      >
        Add payment structure
      </div>
      {Object.keys(brandPaymentStructures).length > 0 && (
        <BrandPaymentStructureTable
          paymentStructuresData={brandPaymentStructures}
          header={brand.name + "'s Payment Structure"}
        />
      )}
      {Object.keys(defaultPaymentStructures).length > 0 && (
        <BrandPaymentStructureTable
          paymentStructuresData={defaultPaymentStructures}
          header={"Default Payment Structure"}
        />
      )}
      <Modal
        isModalOpen={isNewPaymentStructureModalOpen}
        onClose={() => setIsNewPaymentStructureModalOpen(false)}
      >
        <NewBrandPaymentStructure
          brand={brand}
          defaultPaymentStructures={defaultPaymentStructures}
          submitted={() => setIsNewPaymentStructureModalOpen(false)}
        />
      </Modal>
    </div>
  );
}
