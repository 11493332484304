import { useQuery } from "@apollo/client";
import { Brand, Spinner } from "@kalecard/common";
import { useState } from "react";
import {
  BRAND_PAYMENT_STRUCTURES,
  BrandPaymentStructuresInterface,
} from "../graphql/queries";
import { buildPaymentStructuresGroups } from "../reducers/BrandPaymentStructuresReducer";
import BrandPaymentStructuresProvider from "../providers/BrandPaymentStructuresProvider";
import BrandPaymentStructures from "../components/brands/BrandPaymentStructures";

export default function BrandPaymentStructuresPage({
  brand,
}: {
  brand: Brand;
}) {
  const [brandPaymentStructuresData, setBrandPaymentStructuresData] = useState(
    {}
  );
  const [defaultPaymentStructuresData, setDefaultPaymentStructuresData] =
    useState({});
  const [paymentStructuresDataLoading, setPaymentStructuresDataLoading] =
    useState(false);

  const { data, loading } = useQuery<BrandPaymentStructuresInterface>(
    BRAND_PAYMENT_STRUCTURES,
    {
      fetchPolicy: "network-only",
      skip:
        Object.keys(brandPaymentStructuresData).length > 0 ||
        Object.keys(defaultPaymentStructuresData).length > 0,
      variables: {
        brandId: brand.id,
      },
      onCompleted: (data) => {
        setPaymentStructuresDataLoading(true);
        const [brandData, defaultData] = buildPaymentStructuresGroups(
          data.brandPaymentStructures,
          brand.id,
          brandPaymentStructuresData,
          defaultPaymentStructuresData
        );
        setBrandPaymentStructuresData(brandData);
        setDefaultPaymentStructuresData(defaultData);
        setPaymentStructuresDataLoading(false);
      },
    }
  );

  return !loading && !paymentStructuresDataLoading ? (
    <BrandPaymentStructuresProvider
      brandPaymentStructuresState={{
        brandPaymentStructures: brandPaymentStructuresData,
        defaultPaymentStructures: defaultPaymentStructuresData,
      }}
    >
      <BrandPaymentStructures brand={brand} />
    </BrandPaymentStructuresProvider>
  ) : (
    <div className="flex justify-center pt-10">
      <Spinner size={"h-8 w-8"} />
    </div>
  );
}
