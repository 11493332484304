import { GET_BRAND_SAGE_SETTINGS } from "../graphql/queries";
import {
  GetSageSettingsQuery,
  GetSageSettingsQueryVariables,
  SageSettings,
} from "../__generated__/graphql";
import { SageForm } from "../components/sage/SageForm";
import { useQuery } from "@apollo/client";
import { Brand } from "@kalecard/common";

export function SagePage(props: { brand: Brand }) {
  const { data: settings, loading } = useQuery<
    GetSageSettingsQuery,
    GetSageSettingsQueryVariables
  >(GET_BRAND_SAGE_SETTINGS, {
    variables: {
      brandId: props.brand.id,
    },
  });

  const sageSettings = settings?.getSageSettings as SageSettings;

  if (loading) {
    return <div>Loading...</div>;
  }
  return <SageForm settings={sageSettings} />;
}
