import { Heading } from "../catalyst/heading";
import { Switch } from "../catalyst/switch";
import { Divider } from "../catalyst/divider";
import { SET_BRAND_SAGE_PACING } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { SetBrandSagePacingMutationVariables } from "../../__generated__/graphql";
import { Description, Field } from "../catalyst/fieldset";

interface SetBrandPacingRowProps {
  brandId: string;
  pacingEnabled: boolean;
}

export function SetBrandPacingRow(props: SetBrandPacingRowProps) {
  const [setBrandSagePacing] = useMutation<SetBrandSagePacingMutationVariables>(
    SET_BRAND_SAGE_PACING
  );

  return (
    <div>
      <div className="mb-2 flex flex-row justify-between">
        <Heading>Pacing with Sage</Heading>
        <div className={"flex flex-col justify-start"}>
          <Switch
            checked={props.pacingEnabled}
            name="enable_pacing"
            color="green"
            className="mt-2"
            onChange={async (checked) => {
              await setBrandSagePacing({
                variables: {
                  id: props.brandId,
                  enablePacing: checked,
                },
              });
            }}
          />
        </div>
      </div>
      <Divider />
    </div>
  );
}
