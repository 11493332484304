import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Description,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from "../../catalyst/fieldset";
import { BuildingStorefrontIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { Button } from "../../catalyst/button";
import { Brand, Spinner } from "@kalecard/common";
import { upload } from "../../../utils/upload";
import { useMutation } from "@apollo/client";
import { UPDATE_BRAND_ASSETS } from "../../../graphql/mutations";

interface BrandAssetsInput {
  logoFiles?: File[];
  coverFiles?: File[];
}

export default function BrandAssets(props: { brand: Brand }) {
  const [logoSrc, setLogoSrc] = useState<string>(props.brand.logoUrl);
  const [coverSrc, setCoverSrc] = useState<string>(props.brand.coverUrl);
  const [logoError, setLogoError] = useState(null);
  const [coverPhotoError, setCoverPhotoError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };

  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const clearLogoErrorMessage = () => {
    timerRef.current = setTimeout(() => setLogoError(null), 5000);
  };

  const clearCoverErrorMessage = () => {
    timerRef.current = setTimeout(() => setCoverPhotoError(null), 5000);
  };

  const [updateBrandAssets] = useMutation(UPDATE_BRAND_ASSETS);

  const { handleSubmit, watch, register } = useForm<BrandAssetsInput>();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "logoFiles") {
        if (
          FileReader && value.logoFiles.length > 0
            ? value.logoFiles[0].name
            : null
        ) {
          var fr = new FileReader();
          fr.onload = function () {
            const img = new Image();
            img.src = String(fr.result);
            img.onload = () => {
              if (img.width < 512 || img.height < 512) {
                setLogoError(
                  "Oops! Your image resolution is too low, please use a higher resolution. Minimum resolution is 512x512 px."
                );
                clearLogoErrorMessage();
                return;
              } else if (isImageRatioCorrect(img.width, img.height) !== true) {
                setLogoError(
                  "Oops! Your image isn't the right size. It must have 1:1 ratio."
                );
                clearLogoErrorMessage();
                return;
              } else {
                setLogoError(null);
                setLogoSrc(String(fr.result));
              }
            };
          };
          fr.readAsDataURL(value.logoFiles[0]);
        }
      } else if (name === "coverFiles") {
        if (
          FileReader && value.coverFiles.length > 0
            ? value.coverFiles[0].name
            : null
        ) {
          var fr = new FileReader();
          fr.onload = function () {
            const img = new Image();
            img.src = String(fr.result);
            img.onload = () => {
              if (img.width < 512 || img.height < 512) {
                setCoverPhotoError(
                  "Oops! Your image resolution is too low, please use a higher resolution."
                );
                clearCoverErrorMessage();
                return;
              } else if (
                isImageRatioCorrect(img.width, img.height, 16, 9) !== true
              ) {
                setCoverPhotoError(
                  "Oops! Your image isn't the right size. It must have a 16:9 ratio."
                );
                clearCoverErrorMessage();
                return;
              } else {
                setCoverPhotoError(null);
                setCoverSrc(String(fr.result));
              }
            };
          };
          fr.readAsDataURL(value.coverFiles[0]);
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const onSubmit: SubmitHandler<BrandAssetsInput> = async (
    data: BrandAssetsInput
  ) => {
    if (
      (data.logoFiles.length === 0 && !props.brand.logoUrl) ||
      (data.coverFiles.length === 0 && !props.brand.coverUrl)
    ) {
      setErrorMessage(
        "Please upload both a logo and a cover photo for the brand."
      );
      clearErrorMessage();
      return;
    }
    setLoading(true);

    var uploadLogoResult = null;
    if (data.logoFiles.length > 0) {
      uploadLogoResult = await upload(data.logoFiles[0], "BRAND_LOGO", {
        data: [{ key: "brandId", value: props.brand.id }],
      });
    }

    var uploadCoverResult = null;
    if (data.coverFiles.length > 0) {
      uploadCoverResult = await upload(data.coverFiles[0], "BRAND_COVER", {
        data: [{ key: "brandId", value: props.brand.id }],
      });
    }

    const variables = {
      brandId: props.brand.id,
      logoBucketPath: uploadLogoResult?.bucketPath,
      coverBucketPath: uploadCoverResult?.bucketPath,
    };

    try {
      await updateBrandAssets({
        variables: variables,
      });
      setSuccessMessage("Congrats, your changes have been applied!");
      clearSuccessMessage();
    } catch (err) {
      console.error(err);
      setErrorMessage("Failed to apply your changes, try again later.");
      clearErrorMessage();
    }
    setLoading(false);
  };

  return (
    <form className="space-y-4" key="assets">
      <Fieldset>
        <div className="flex justify-center">
          <Legend>Brand Assets</Legend>
        </div>
        <FieldGroup>
          <div className="flex flex-row space-x-10">
            <Field>
              <Label>Logo</Label>
              <Description>Image must a square with a 1:1 ratio.</Description>
              <div className="flex items-center space-x-2">
                {logoSrc ? (
                  <img
                    className="h-12 w-12 rounded-full border border-kale-green-500"
                    src={logoSrc}
                  />
                ) : (
                  <BuildingStorefrontIcon className="inline-block h-12 w-12 rounded-full" />
                )}
                <label className="ml-5 cursor-pointer rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="text-base leading-normal">Change</span>
                  <input
                    className="hidden"
                    {...register("logoFiles")}
                    accept="image/png,image/jpeg"
                    type="file"
                    name="logoFiles"
                  />
                </label>
              </div>

              {logoError && (
                <div className="pt-2 text-sm font-medium text-red-500">
                  {logoError}
                </div>
              )}
            </Field>
            <Field>
              <Label>Cover Photo</Label>
              <Description>Image must have a 16:9 ratio.</Description>
              <div className="flex items-center space-x-2">
                {coverSrc ? (
                  <img
                    className="h-36 w-64 border border-kale-green-500 object-cover"
                    src={coverSrc}
                  />
                ) : (
                  <PhotoIcon className="inline-block h-12 w-12" />
                )}
                <label className="ml-5 cursor-pointer rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="text-base leading-normal">Change</span>
                  <input
                    className="hidden"
                    {...register("coverFiles")}
                    accept="image/png,image/jpeg"
                    type="file"
                    name="coverFiles"
                  />
                </label>
              </div>
              {coverPhotoError && (
                <div className="pt-2 text-sm font-medium text-red-500">
                  {coverPhotoError}
                </div>
              )}
            </Field>
          </div>
        </FieldGroup>
      </Fieldset>
      <div className="flex flex-row items-center justify-end space-x-4">
        {successMessage && (
          <p className="text-sm font-medium text-green-500">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-sm font-medium text-red-500">{errorMessage}</p>
        )}
        {loading && (
          <div className="flex flex-wrap content-center justify-center pr-2">
            <Spinner size="h-6 w-6" />
          </div>
        )}
        <Button color="indigo" onClick={handleSubmit(onSubmit)}>
          Save Changes
        </Button>
      </div>
    </form>
  );
}

export function isBrandAssetsStepComplete(brand: Brand): boolean {
  return !!(brand.logoUrl && brand.coverUrl);
}

function isImageRatioCorrect(
  width,
  height,
  desiredRatioWidth = 1,
  desiredRatioHeight = 1,
  tolerance = 0.05
) {
  const ratio = width / height;
  const desiredRatio = desiredRatioWidth / desiredRatioHeight;
  return Math.abs(ratio - desiredRatio) <= tolerance;
}
