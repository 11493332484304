import { useQuery } from "@apollo/client";
import { useContext, useState } from "react";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import ApiError from "../components/ApiError";
import CreatorDeals from "../components/creator/CreatorDeals";
import CreatorHeader from "../components/creator/CreatorHeader";
import CreatorMessages from "../components/creator/CreatorMessages";
import { CreatorProfileData, Loading } from "@kalecard/common";
import { CREATOR_PROFILE_DATA } from "../graphql/queries";
import { UserContext } from "../providers/UserProvider";
import CreatorPosts from "../components/creator/CreatorPosts";
import DealHeader from "../components/creator/DealHeader";
import CreatorActions from "../components/creator/CreatorActions";
import CreatorTransactions from "../components/creator/CreatorTransactions";

interface CreatorPageInterface { }

export default function CreatorPage({ }: CreatorPageInterface) {
  const { user } = useContext(UserContext);
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const creatorId = params["id"];
  const dealId = queryParams.get("dealId");
  const [creatorProfileData, setCreatorProfileData] =
    useState<CreatorProfileData>(null);

  const loadData = (data) => {
    setCreatorProfileData(data.creatorProfileData);
  };

  const { called, error, loading } = useQuery(CREATOR_PROFILE_DATA, {
    variables: {
      userId: creatorId,
    },
    onCompleted: loadData,
  });

  if (!user || loading || !called) return <Loading />;
  if (error) return <ApiError error={error} />;

  return (
    <div className="py-6">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        {creatorProfileData && (
          <CreatorHeader creatorProfileData={creatorProfileData} />
        )}
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <Switch>
          <Route path="/creators/:id/deals">
            {creatorProfileData && (
              <CreatorDeals creator={creatorProfileData.creator} />
            )}
          </Route>
          <Route path="/creators/:id/post">
            <DealHeader userId={creatorId} dealId={dealId} />
            <CreatorPosts
              userId={creatorId}
              dealId={dealId}
              creator={creatorProfileData?.creator}
            />
          </Route>
          <Route path="/creators/:id/messages">
            <DealHeader userId={creatorId} />
            {creatorProfileData && (
              <CreatorMessages
                id={creatorId}
                phoneNumber={creatorProfileData?.creator.phoneNumber}
              />
            )}
          </Route>
          <Route path="/creators/:id/transactions">
            {creatorProfileData && <CreatorTransactions creator={creatorProfileData.creator} />}
          </Route>
          <Route path="/creators/:id/actions">
          {creatorProfileData && <CreatorActions creator={creatorProfileData.creator} />}
          </Route>
          <Route path="/creators/:id">
            <DealHeader userId={creatorId} />
            {creatorProfileData && (
              <CreatorMessages
                id={creatorId}
                phoneNumber={creatorProfileData?.creator.phoneNumber}
              />
            )}
          </Route>
        </Switch>
      </div>
    </div>
  );
}
