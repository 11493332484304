import { useMutation } from "@apollo/client";
import { Dropdown, Offer, OptionInterface, Spinner } from "@kalecard/common";
import { CREATE_OFFER } from "../../graphql/mutations";
import { useEffect, useState } from "react";
import { BRANDS } from "../../graphql/queries";
import { getAllBrands } from "../../utils/brands";
import { BuildingStorefrontIcon } from "@heroicons/react/24/solid";
import { SubmitHandler, useForm } from "react-hook-form";

interface NewOfferFormInterface {
  onSubmit?: (newOffer: Offer) => void;
}

type NewOfferFormInputs = {
  cashbackPercentage?: number;
  kaleFeePercentage?: number;
};

export default function NewOfferForm({ onSubmit }: NewOfferFormInterface) {
  const [createOffer] = useMutation(CREATE_OFFER);
  const [brandOptions, setBrandOptions] = useState<OptionInterface[]>();
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { reset, register, handleSubmit, watch, getValues, setValue } =
    useForm<NewOfferFormInputs>();

  useEffect(() => {
    getAllBrands(BRANDS).then((brands) => {
      setBrandOptions(
        brands.map((brand) => {
          return {
            id: brand.id,
            name: brand.name,
            imageUrl: brand.logoUrl,
          };
        })
      );
    });
  }, []);

  const brandClicked = (brandOption: OptionInterface) => {
    setSelectedBrandId(brandOption.id);
  };

  const submit: SubmitHandler<NewOfferFormInputs> = async (
    data: NewOfferFormInputs
  ) => {
    if (
      data.cashbackPercentage < 0 ||
      data.cashbackPercentage > 99 ||
      data.cashbackPercentage % 1 != 0
    ) {
      setError("Invalid input for cashback percentage.");
      reset();
      return;
    }

    if (
      data.kaleFeePercentage < 0 ||
      data.kaleFeePercentage > 99 ||
      data.kaleFeePercentage % 1 != 0
    ) {
      setError("Invalid input for Kale fee percentage.");
      reset();
      return;
    }

    console.log(data, selectedBrandId);
    setIsLoading(true);
    await createOffer({
      variables: {
        brandId: selectedBrandId,
        cashbackPercentage: data.cashbackPercentage / 100,
        kaleFeePercentage: data.kaleFeePercentage / 100,
      },
      onCompleted: (data) => {
        console.log(data);
        onSubmit(data.createOffer as Offer);
      },
      onError: (error, clientOptions) => {
        if (error.graphQLErrors) {
          const firstError = error.graphQLErrors[0];
          if (firstError.extensions?.errorType === "FAILED_PRECONDITION") {
            setError(firstError?.message);
            return;
          }
        }
        setError("Something went wrong. Try again later.");
        console.error(error.message);
      },
    });
    setIsLoading(false);
  };

  return (
    <form className="m-4">
      <div className="flex flex-col space-y-4">
        {/* Brand */}
        <div>
          <Dropdown
            options={brandOptions}
            activeOptionId={selectedBrandId}
            optionClicked={brandClicked}
            label={"Brand:"}
            placeholderImage={
              <BuildingStorefrontIcon className="h-6 w-6 flex-shrink-0 rounded-full" />
            }
          />
        </div>
        {/* Cashback Percentage */}
        <div className="mt-2">
          <label
            htmlFor="cashbackPercentage"
            className="block text-sm font-medium text-gray-700"
          >
            Set the creator's cashback percentage:
          </label>
          <div className="mt-1 flex items-center rounded-md">
            <input
              {...register("cashbackPercentage")}
              name="cashbackPercentage"
              id="cashbackPercentage"
              className="w-16 rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
              type="number"
              step="1"
              max={99}
              min={0}
              defaultValue={15}
            />
            <p className="pl-2 text-sm font-medium text-gray-700">%</p>
          </div>
        </div>
        <div className="mt-2">
          <label
            htmlFor="kaleFeePercentage"
            className="block text-sm font-medium text-gray-700"
          >
            Set the Kale fee percentage:
          </label>
          <div className="mt-1 flex items-center rounded-md">
            <input
              {...register("kaleFeePercentage")}
              name="kaleFeePercentage"
              id="kaleFeePercentage"
              className="w-16 rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
              type="number"
              step="1"
              max={99}
              min={0}
              defaultValue={0}
            />
            <p className="pl-2 text-sm font-medium text-gray-700">%</p>
          </div>
        </div>
        <div className="flex justify-end space-x-2">
          {isLoading && (
            <div className="flex flex-wrap content-center justify-center">
              <Spinner size="h-6 w-6" />
            </div>
          )}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleSubmit(submit)}
              disabled={isLoading}
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400"
            >
              Create Offer
            </button>
          </div>
        </div>
        <div className="mt-2 flex justify-end">
          <p className="text-xs font-bold text-red-500">{error}</p>
        </div>
      </div>
    </form>
  );
}
