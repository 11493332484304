import { Brand, Spinner } from "@kalecard/common";
import { POST_REQUIREMENTS, PostRequirementsInterface } from "../graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { ADD_POST_REQUIREMENT, UPDATE_POST_REQUIREMENT, UPDATE_POST_REQUIREMENTS_FOR_BRAND } from "../graphql/mutations";
import { buildTemplatedRequirement } from "../components/shared/PostRequirementRow";
import PostRequirementsTable from "../components/shared/PostRequirementsTable";

export default function BrandRequirementsPage({ brand }: { brand: Brand }) {
  const { data, loading, refetch } = useQuery<PostRequirementsInterface>(POST_REQUIREMENTS, {
    variables: { brandId: brand.id },
    fetchPolicy: 'network-only'
  });

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [updatePostRequirementsForBrand] = useMutation(UPDATE_POST_REQUIREMENTS_FOR_BRAND, { refetchQueries: [POST_REQUIREMENTS] });
  const [addPostRequirement] = useMutation(ADD_POST_REQUIREMENT, { refetchQueries: [POST_REQUIREMENTS] });
  const [updatePostRequirement] = useMutation(UPDATE_POST_REQUIREMENT, { refetchQueries: [POST_REQUIREMENTS] });
  const [updatedPostRequirements, setUpdatedPostRequirements] = useState(data?.postRequirements ?? []);

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };

  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const handleSave = async () => {
    setLoadingUpdate(true);
    try {
      const result = await updatePostRequirementsForBrand({
        variables: {
          brandId: brand.id,
          postRequirementIds: updatedPostRequirements
            .filter(
              (postRequirement) =>
                postRequirement.isMappedToBrand === true
            )
            .map(
              (postRequirement) => postRequirement.id
            ),
        },
      });
      console.log(result);
      if (result.data.updatePostRequirementsForBrand == true) {
        setErrorMessage(null);
        setSuccessMessage("Congrats! Your changes have been applied.");
        clearSuccessMessage();
      } else {
        setSuccessMessage(null);
        setErrorMessage(
          "Something went wrong, try refreshing this page or try again later."
        );
        clearErrorMessage();
      }
    } catch (err) {
      console.error(err);
      setSuccessMessage(null);
      setErrorMessage("Something went wrong, please try again later.");
      clearErrorMessage();
    }
    setLoadingUpdate(false);
  };

  const handleToggleMapToBrand = (postRequirement) => {
    const requirements = updatedPostRequirements.map((requirement) => {
      if (requirement.id === postRequirement.id) {
        return {
          ...requirement,
          isMappedToBrand: !requirement.isMappedToBrand
        }
      } else {
        return requirement;
      }
    });

    setUpdatedPostRequirements(requirements);
  };

  const handleNewRequirement = (postRequirement) => {
    addPostRequirement({
      variables: {
        requirement: buildTemplatedRequirement(postRequirement.requirement, brand),
        iconFilename: postRequirement.iconFilename,
        brandId: brand.id,
        emoji: postRequirement.emoji
      },
    })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleUpdateRequirement = (postRequirement) => {
    updatePostRequirement({
      variables: {
        postRequirementId: postRequirement.id,
        requirement: buildTemplatedRequirement(postRequirement.requirement, brand),
        iconFilename: postRequirement.iconFilename,
        emoji: postRequirement.emoji
      },
    })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (data?.postRequirements) {
      setUpdatedPostRequirements(data.postRequirements);
    }
  }, [data]);

  return (
    !loading ? (
      <div className="rounded-md border-2 p-2">
        <PostRequirementsTable
          ownerName={brand.name}
          brand={brand}
          showRequiredColumn={true}
          postRequirements={updatedPostRequirements}
          handleToggleMapToBrand={handleToggleMapToBrand}
          handleNewRequirement={handleNewRequirement}
          handleUpdateRequirement={handleUpdateRequirement}
          showSave={true}
          handleSave={handleSave}
          showTableHeader={true}
          postRequirementsSaveData={{ successMessage: successMessage, errorMessage: errorMessage, loadingUpdate: loadingUpdate }}
        />
      </div>
    ) : (
      <div className="flex justify-center pt-10">
        <Spinner size={"h-8 w-8"} />
      </div>
    )
  );
}