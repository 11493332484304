import { Brand, PostRequirement } from "@kalecard/common";
import { useForm } from "react-hook-form";
import { FaceSmileIcon } from "@heroicons/react/24/outline";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useState } from "react";

function formatRequirement(requirement: string, brand: Brand): string {
  return requirement.replaceAll("{{brand_name}}", brand?.name ?? "<BRAND>");
}

export function PostRequirementComponent({
  postRequirement,
  brand,
  postRequirementIdx,
  showRequiredColumn,
  handleUpdateRequirement,
  handleToggleMapToBrand,
  onRemove,
}: {
  postRequirement: PostRequirement;
  brand: Brand;
  postRequirementIdx: number;
  showRequiredColumn: boolean;
  handleUpdateRequirement: (postRequirement: PostRequirement) => void;
  handleToggleMapToBrand?: (postRequirement: PostRequirement) => void;
  onRemove?: (postRequirement: PostRequirement) => void;
}) {
  const [isUpdateView, setIsUpdateView] = useState(false);

  return (
    <>
      <tr className={postRequirementIdx % 2 === 0 ? undefined : "bg-gray-50"}>
        {isUpdateView ? (
          <UpdatePostRequirementView
            postRequirement={postRequirement}
            brand={brand}
            handleUpdateRequirement={handleUpdateRequirement}
            setIsUpdateView={setIsUpdateView}
          />
        ) : (
          <>
            <td className="whitespace-nowrap py-4 pl-3 pr-3 text-lg font-medium text-gray-900">
              {postRequirement.emoji}
            </td>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
              {formatRequirement(postRequirement.requirement, brand)}
            </td>
            {showRequiredColumn && (
              <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                <div className="flex">
                  <input
                    type="checkbox"
                    name="mappedToBrand"
                    id="mappedToBrand"
                    checked={postRequirement.isMappedToBrand}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600"
                    onChange={() => handleToggleMapToBrand(postRequirement)}
                  />
                </div>
              </td>
            )}
            <td className="relative space-x-2 whitespace-nowrap py-4 pl-3 pr-3 text-right text-sm font-medium">
              <button
                type="button"
                className="text-indigo-600 hover:text-indigo-900"
                onClick={() => {
                  setIsUpdateView(true);
                }}
              >
                Edit<span className="sr-only"></span>
              </button>
              {!showRequiredColumn && (
                <button
                  type="button"
                  className="text-red-600 hover:text-red-900"
                  onClick={() => onRemove(postRequirement)}
                >
                  Remove<span className="sr-only"></span>
                </button>
              )}
            </td>
          </>
        )}
      </tr>
    </>
  );
}

type PostRequirementInputs = {
  requirement?: string;
  iconFilename?: string;
  emoji?: string;
};

export function buildTemplatedRequirement(
  requirement: string,
  brand: Brand
): string {
  return requirement.replaceAll(brand.name, "{{brand_name}}");
}

export function NewPostRequirement({
  brand,
  handleNewRequirement,
}: {
  brand: Brand;
  handleNewRequirement: (postRequirement: PostRequirement) => void;
}) {
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);

  const {
    reset,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<PostRequirementInputs>();

  return (
    <>
      <td className="whitespace-nowrap py-4 pl-3 text-sm">
        <div className="flex space-x-2">
          <div>
            <button
              type="button"
              onClick={() => {
                setIsEmojiOpen(!isEmojiOpen);
              }}
            >
              {getValues("emoji") ? (
                <p className="text-lg">{getValues("emoji")}</p>
              ) : (
                <FaceSmileIcon className="h-5 w-5" />
              )}
            </button>
          </div>

          {isEmojiOpen && (
            <div className="absolute z-10">
              <Picker
                data={data}
                onEmojiSelect={(emoji) => {
                  setValue("emoji", emoji.native);
                  setIsEmojiOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </td>
      <td className="whitespace-nowrap p-4 text-sm">
        <input
          type="text"
          name="requirement"
          {...register("requirement", { required: true })}
          id="requirement"
          placeholder="Requirement"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
        />
      </td>
      <td className="whitespace-nowrap text-sm font-medium">
        <div className="flex space-x-2 text-right">
          <button
            type="button"
            className="text-indigo-600 hover:text-indigo-900"
            onClick={() => {
              handleNewRequirement({
                requirement: getValues("requirement"),
                emoji: getValues("emoji"),
              });
              reset({
                requirement: null,
                emoji: null,
              });
            }}
          >
            Add
          </button>
        </div>
      </td>
      <td></td>
    </>
  );
}

export function UpdatePostRequirementView({
  postRequirement,
  brand,
  handleUpdateRequirement,
  setIsUpdateView,
}: {
  postRequirement: PostRequirement;
  brand: Brand;
  handleUpdateRequirement: (postRequirement: PostRequirement) => void;
  setIsUpdateView?: (isUpdateView: boolean) => void;
}) {
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);

  const {
    reset,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<PostRequirementInputs>({
    defaultValues: {
      requirement: postRequirement.requirement
        ? formatRequirement(postRequirement.requirement, brand)
        : null,
      iconFilename: postRequirement.iconFilename,
      emoji: postRequirement.emoji,
    },
  });

  return (
    <>
      <td className="whitespace-nowrap py-4 text-sm sm:pl-3">
        <div className="flex flex-col space-y-2">
          <div>
            <button
              type="button"
              onClick={() => {
                setIsEmojiOpen(!isEmojiOpen);
              }}
            >
              {getValues("emoji") ? (
                <p className="text-lg">{getValues("emoji")}</p>
              ) : (
                <FaceSmileIcon className="h-5 w-5" />
              )}
            </button>
          </div>

          {isEmojiOpen && (
            <div className="absolute z-10">
              <Picker
                data={data}
                onEmojiSelect={(emoji) => {
                  setValue("emoji", emoji.native);
                  setIsEmojiOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </td>
      <td className="whitespace-nowrap p-4 text-sm">
        <input
          type="text"
          name="requirement"
          {...register("requirement", { required: true })}
          id="requirement"
          placeholder="Requirement"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
        />
      </td>
      <td className="whitespace-nowrap text-sm font-medium">
        <div className="flex space-x-2 text-right">
          <button
            className="text-indigo-600 hover:text-indigo-900"
            onClick={() => {
              handleUpdateRequirement({
                id: postRequirement.id,
                requirement: getValues("requirement"),
                emoji: getValues("emoji"),
              });
              setIsUpdateView(false);
              reset({
                requirement: null,
                emoji: null,
                iconFilename: null,
              });
            }}
          >
            Update
          </button>
          <button
            className="text-red-600 hover:text-red-900"
            onClick={() => {
              reset({
                requirement: null,
                emoji: null,
                iconFilename: null,
              });
              setIsUpdateView(false);
            }}
          >
            Cancel
          </button>
        </div>
      </td>
      <td></td>
    </>
  );
}
