import { Spinner } from "@kalecard/common";
import ChallengesProvider from "../providers/ChallengesProvider";
import { useQuery } from "@apollo/client";
import {
  ADMIN_CHALLENGES,
  CHALLENGES_BADGES,
  ChallengesBadgesInterface,
  ChallengesData,
} from "../graphql/queries";
import {
  SortableChallengeColumnType,
  SortableColumn,
  challengesSortingTypes,
} from "../reducers/ChallengesReducer";
import AdminChallenges from "../components/challenges/AdminChallenges";

export default function ChallengesPage() {
  const { data, loading } = useQuery<ChallengesData>(ADMIN_CHALLENGES, {
    fetchPolicy: "network-only",
  });

  const { data: challengesBadgesData, loading: challengesBadgesLoading } =
    useQuery<ChallengesBadgesInterface>(CHALLENGES_BADGES, {
      fetchPolicy: "network-only",
    });

  const initializedSortableColumns: SortableColumn[] = [
    {
      key: SortableChallengeColumnType.CLAIMS,
      value: {
        name: "Claims",
        selected: false,
        sortingType: "TOTAL_CLAIMS_DESC",
      },
    },
    {
      key: SortableChallengeColumnType.POSTS,
      value: {
        name: "Posts",
        selected: false,
        sortingType: "TOTAL_POSTS_DESC",
      },
    },
    {
      key: SortableChallengeColumnType.POST_RATE,
      value: {
        name: "Post Rate",
        selected: false,
        sortingType: "POST_RATE_DESC",
      },
    },
  ];

  return !loading && !challengesBadgesLoading ? (
    <ChallengesProvider
      challengesState={{
        challenges: data.adminChallenges.edges.map((edge) => edge.node),
        hasNextPage: data.adminChallenges.pageInfo.hasNextPage,
        after: data?.adminChallenges.edges.length.toString(),
        brand: null,
        challengeState: null,
        newChallenges: [],
        sortingType: challengesSortingTypes[0],
        keywords: [],
        brands: null,
        challengeIds: null,
        shouldUpdateChallenges: false,
        pendingChallengesCount:
          challengesBadgesData.challengesBadges.pendingReviewFromBrand,
        sortableColumns: initializedSortableColumns,
        feedbackCount: challengesBadgesData.challengesBadges.newFeedbackFromBrand,
        additionalFilters: { containingFeedback: false, commercialSoundsOnly: false }
      }}
    >
      <AdminChallenges />
    </ChallengesProvider>
  ) : (
    <div className="flex justify-center pt-10">
      <Spinner size={"h-8 w-8"} />
    </div>
  );
}
