import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { BRAND_TRANSACTIONS } from "../../graphql/queries";
import {
  Brand,
  BrandTransaction,
  Edge,
  Spinner,
  prettyDecimal,
} from "@kalecard/common";
import { BrandTransactionsQuery } from "../../__generated__/graphql";

export default function BrandTransactions({ brand }: { brand: Brand }) {
  const [edges, setEdges] = useState([]);
  const [after, setAfter] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [brandTransactionsQuery] =
    useLazyQuery<BrandTransactionsQuery>(BRAND_TRANSACTIONS);

  const loadMore = async () => {
    setLoading(true);
    try {
      const result = await brandTransactionsQuery({
        variables: {
          brandId: brand.id,
          first: 20,
          after: after,
        },
        fetchPolicy: "network-only",
      });
      console.log(result);
      setHasNextPage(result.data.brandTransactions.pageInfo.hasNextPage);
      setAfter(
        result.data.brandTransactions.edges[
          result.data.brandTransactions.edges.length - 1
        ]?.cursor
      );
      setEdges([...edges, ...result.data.brandTransactions.edges]);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (edges.length === 0) {
      loadMore();
    }
    return () => {};
  }, []);

  var listFooter = null;
  if (hasNextPage) {
    listFooter = (
      <button
        className="rounded border border-green-500 bg-transparent px-6 py-2 text-center font-semibold text-green-700 hover:border-transparent hover:bg-green-500 hover:text-white"
        onClick={() => loadMore()}
      >
        Load more
      </button>
    );
  }

  if (loading) {
    listFooter = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  return (
    <>
      <div className="mt-4 flex flex-col items-center">
        <div className="mb-2 w-full overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-y-visible border-2 border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Billed Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Effective Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Payment Method
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {edges.map((edge: Edge<BrandTransaction>) => {
                    return (
                      <tr key={edge.node.id}>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">
                          {edge.node.id}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">
                          <div className="flex flex-col">
                            {edge.node.stripeUrl ? (
                              <a
                                className="font-bold text-kale-green-500 opacity-70 hover:opacity-100"
                                target="_blank"
                                rel="noreferrer"
                                href={edge.node.stripeUrl}
                              >
                                {edge.node.description}
                              </a>
                            ) : (
                              <p className="font-bold">
                                {edge.node.description}
                              </p>
                            )}
                            <p className="text-xs">{edge.node.type}</p>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">
                          ${prettyDecimal(edge.node.amount)}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">
                          {new Date(
                            Number(edge.node.createdAt)
                          ).toLocaleDateString("en-us", {
                            hourCycle: "h12",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">
                          {new Date(
                            Number(edge.node.effectiveAt)
                          ).toLocaleDateString("en-us", {
                            hourCycle: "h12",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">
                          {edge.node.paymentMethodType}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {listFooter}
      </div>
    </>
  );
}
