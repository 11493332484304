export function TemporalDealWorkflowLink({ dealId }: { dealId: string }) {
  const link = `https://cloud.temporal.io/namespaces/next.vdiex/workflows?query=%60WorkflowId%60%3D"main-deal-${dealId}"+OR+%60WorkflowId%60%3D"deal-v2-${dealId}"`;
  return (
    <a
      className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {dealId}
    </a>
  );
}
export function TemporalDownloadPostMediaWorkflowLink({
  postId,
}: {
  postId: string;
}) {
  const link = `https://cloud.temporal.io/namespaces/next.vdiex/workflows?query=%60WorkflowId%60%3D"download-post-media-workflow-${postId}"`;
  return (
    <a
      className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      download-post-media-workflow-{postId}
    </a>
  );
}
