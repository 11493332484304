import { Brand, Spinner } from "@kalecard/common";
import { useEffect, useState } from "react";
import BrandsTable from "../components/brands/BrandsTable";
import NewBrandForm from "../components/forms/NewBrandForm";
import { BRANDS } from "../graphql/queries";
import { getAllBrands } from "../utils/brands";
import { Dialog } from "../components/catalyst/dialog";
import { Button } from "../components/catalyst/button";

export default function NonKaleBrandsPage() {
  const [newBrands, setNewBrands] = useState<Brand[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [isNewBrandModalOpen, setIsNewBrandModalOpen] = useState(false);

  const handleNewBrand = (newBrand: Brand) => {
    setIsNewBrandModalOpen(false);
    setNewBrands([newBrand, ...newBrands]);
  };

  useEffect(() => {
    getAllBrands(BRANDS, null, false, "CREATED_AT_DESC").then((brandData) => setBrands(brandData));
  }, []);

  return (
    <>
      <div className="mt-4 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Brands</h1>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Button color="indigo" onClick={() => setIsNewBrandModalOpen(true)}>
              New Brand
            </Button>
          </div>
        </div>
        {newBrands.length > 0 && (
          <>
            <h1 className="mt-4 text-base font-semibold text-gray-900">
              New Brands
            </h1>
            <BrandsTable
              key={newBrands.length}
              isKaleBrand={false}
              brands={newBrands}
            />
          </>
        )}
        <div className="mt-4">
          <h1 className="text-base font-semibold text-gray-900">
            Non-Kale Brands
          </h1>
          {brands.length === 0 ? (
            <div className="flex justify-center pt-2">
              <Spinner size={"h-8 w-8"} />
            </div>
          ) : (
            <BrandsTable isKaleBrand={false} brands={brands} />
          )}
        </div>
      </div>
      <Dialog
        open={isNewBrandModalOpen}
        onClose={setIsNewBrandModalOpen}
        size="5xl"
      >
        <NewBrandForm newBrand={(brand) => handleNewBrand(brand)} />
      </Dialog>
    </>
  );
}
