import { Modal, Offer } from "@kalecard/common";
import { useContext, useState } from "react";
import NewOfferForm from "../forms/NewOfferForm";
import { OffersContext } from "../../providers/OffersProviders";
import { OffersActionType } from "../../reducers/OffersReducer";
import OffersTable from "./OffersTable";

export default function AdminOffers() {
  const [isNewOfferModalOpen, setIsNewOfferModalOpen] = useState(false);

  const {
    dispatch: offersDispatch,
    state: { offers },
  } = useContext(OffersContext);

  const handleModalSubmit = (newOffer: Offer) => {
    console.log(newOffer);
    setIsNewOfferModalOpen(false);
    offersDispatch({
      type: OffersActionType.NEW_OFFER,
      payload: newOffer,
    });
  };

  return (
    <div className="mt-4 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Offers</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the offers.
          </p>
        </div>
        <div className="mt-4 flex divide-x divide-x-2 sm:ml-16 sm:mt-0">
          <button
            type="button"
            onClick={() => {
              setIsNewOfferModalOpen(true);
            }}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            New Offer
          </button>
        </div>
      </div>
      {offers.length > 0 ? (
        <OffersTable />
      ) : (
        <div className="pt-4 text-sm">No offers yet!</div>
      )}
      <Modal
        isModalOpen={isNewOfferModalOpen}
        onClose={() => setIsNewOfferModalOpen(false)}
        className="w-fit"
      >
        <div className="flex w-full">
          <NewOfferForm onSubmit={handleModalSubmit} />
        </div>
      </Modal>
    </div>
  );
}
