import { Brand, Spinner } from "@kalecard/common";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { UPDATE_BRAND_CREATOR_PAYMENTS_INFO } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import {
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from "../../catalyst/fieldset";
import { Input } from "../../catalyst/input";
import { Text } from "../../catalyst/text";
import { Button } from "../../catalyst/button";
import { BrandSettingsField } from "./BrandSettingsField";

interface BrandCreatorPaymentsInput {
  monthlyMaxCreatorChallenges?: number;
  maxCreatorPayoutUserInput?: number;
  monthlyMaxCreatorPayoutUserInput?: number;
  isKaleOffsetEnabled: boolean;
}

export default function BrandCreatorPayments({ brand }: { brand: Brand }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };

  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const { register, handleSubmit, getValues, setValue } =
    useForm<BrandCreatorPaymentsInput>({
      defaultValues: {
        ...brand,
      },
    });

  const [updateBrandCreatorPayments] = useMutation(
    UPDATE_BRAND_CREATOR_PAYMENTS_INFO
  );

  const onSubmit = async (data: BrandCreatorPaymentsInput) => {
    if (data.isKaleOffsetEnabled == null) {
      setErrorMessage("Please select an option for Kale offset.");
      clearErrorMessage();
      return;
    }

    setLoading(true);
    try {
      const variables = {
        brandId: brand.id,
        monthlyMaxCreatorChallenges: data.monthlyMaxCreatorChallenges
          ? Number(data.monthlyMaxCreatorChallenges)
          : null,
        monthlyMaxCreatorPayout: data.monthlyMaxCreatorPayoutUserInput
          ? Number(data.monthlyMaxCreatorPayoutUserInput)
          : null,
        maxCreatorPayout: data.maxCreatorPayoutUserInput
          ? Number(data.maxCreatorPayoutUserInput)
          : null,
        isKaleOffsetEnabled: data.isKaleOffsetEnabled,
      };

      await updateBrandCreatorPayments({
        variables: variables,
      });
      setErrorMessage(null);
      setSuccessMessage("Congrats! Your changes have been applied.");
      clearSuccessMessage();
    } catch (err) {
      console.error(err);
      setErrorMessage("Failed to apply your changes, try again later.");
      clearErrorMessage();
    }
    setLoading(false);
  };

  return (
    <form className="space-y-4" key="payments">
      <Fieldset>
        <div className="flex justify-center">
          <Legend>Creator Payments Info</Legend>
        </div>
        <FieldGroup>
          <div className="flex space-x-10">
            <Field className="w-full">
              <Label>Monthly Max Creator Challenges (Optional)</Label>
              <div>
                <Input
                  {...register("monthlyMaxCreatorChallenges")}
                  type="number"
                  step={1}
                />
              </div>
            </Field>
            <Field className="w-full">
              <Label>Max Creator Payout (Optional)</Label>
              <div className="flex items-center space-x-1">
                <Text>$</Text>
                <Input
                  step={0.01}
                  {...register("maxCreatorPayoutUserInput")}
                  name="maxCreatorPayoutUserInput"
                  id="maxCreatorPayoutUserInput"
                  type="number"
                  placeholder={
                    brand.maxCreatorPayout
                      ? brand.maxCreatorPayout.toString()
                      : "0.00"
                  }
                  prefix="$"
                />
                <Text>USD</Text>
              </div>
            </Field>
            <Field className="w-full">
              <Label>Monthly Max Creator Payout (Optional)</Label>
              <div className="flex items-center space-x-1">
                <Text>$</Text>
                <Input
                  step={0.01}
                  {...register("monthlyMaxCreatorPayoutUserInput")}
                  name="monthlyMaxCreatorPayoutUserInput"
                  id="monthlyMaxCreatorPayoutUserInput"
                  type="number"
                  placeholder={
                    brand.monthlyMaxCreatorPayout
                      ? (Number(brand.monthlyMaxCreatorPayout) / 100).toString()
                      : "0.00"
                  }
                />
                <Text>USD</Text>
              </div>
            </Field>
          </div>
          <div className="grid grid-cols-2">
            <div className="col-span-1 pr-6">
              <BrandSettingsField
                key={"isKaleOffsetEnabled" + getValues("isKaleOffsetEnabled")}
                label="Kale offset"
                description="Whether we should adjust charges of high quality content to meet a minimum threshold."
                name="isKaleOffsetEnabled"
                value={getValues("isKaleOffsetEnabled")}
                setValue={setValue}
              />
            </div>
          </div>
        </FieldGroup>
      </Fieldset>
      <div className="flex flex-row items-center justify-end space-x-4">
        {successMessage && (
          <p className="text-sm font-medium text-green-500">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-sm font-medium text-red-500">{errorMessage}</p>
        )}
        {loading && (
          <div className="flex flex-wrap content-center justify-center pr-2">
            <Spinner size="h-6 w-6" />
          </div>
        )}
        <Button color="indigo" onClick={handleSubmit(onSubmit)}>
          Save Changes
        </Button>
      </div>
    </form>
  );
}

export function isBrandCreatorPaymentsStepComplete(brand: Brand) {
  return brand.isKaleOffsetEnabled != null;
}
